import { DocumentUploadTag } from './tag';

export enum DocumentUploadStatusEnum {
	WAITING = 'WAITING',
	SENT = 'SENT',
	PROCESSED = 'PROCESSED',
}

enum DocumentUploadStatusLabelEnum {
	WAITING = 'En attente',
	SENT = 'Envoyé',
	PROCESSED = 'Traité',
}

const enumToLabelMap = new Map([
	[DocumentUploadStatusEnum.WAITING, DocumentUploadStatusLabelEnum.WAITING],
	[DocumentUploadStatusEnum.SENT, DocumentUploadStatusLabelEnum.SENT],
	[DocumentUploadStatusEnum.PROCESSED, DocumentUploadStatusLabelEnum.PROCESSED],
]);

export const toDocumentUploadStatusLabelEnum = (documentUploadStatus: DocumentUploadStatusEnum) =>
	enumToLabelMap.get(documentUploadStatus);

export type DocumentUploadType = {
	createdAt: Date;
	createdBy: string;
	fileType: string;
	id: string;
	status: DocumentUploadStatusEnum;
	name: string;
	tag: DocumentUploadTag;
};
