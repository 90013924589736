import { Icon, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiEarth, mdiLock } from '@mdi/js';
import { Avatar, Stack } from '@mui/material';

import SignersAvatarGroup from '../components/SignersAvatarGroup';
import { ProcedureType } from '../types/procedure';

export const baseProcedureColumns: TableColumnType<ProcedureType>[] = [
	{
		align: 'center',
		flexGrow: 0,
		field: 'isPrivate',
		key: 'isPrivate',
		title: '',
		render: ({ isPrivate }) => (
			<Tooltip content={isPrivate ? 'Privée' : 'Publique'} placement="right">
				<Stack justifyContent="center" alignItems="center">
					<Icon path={isPrivate ? mdiLock : mdiEarth} />
				</Stack>
			</Tooltip>
		),
		width: 40,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom',
		width: 150,
	},
	{
		field: 'signers',
		key: 'signers',
		title: 'Signataires',
		render: ({ signers }) => <SignersAvatarGroup signers={signers} />,
		width: 150,
	},
	{
		field: 'createdBy',
		key: 'createdBy',
		title: 'Créé par',
		render: ({ createdBy: { firstName, lastName } }) => (
			<Tooltip content={`${firstName ?? ''} ${lastName}`.trim()}>
				<Avatar>{`${firstName?.[0] ?? ''}${lastName[0]}`}</Avatar>
			</Tooltip>
		),
		width: 100,
	},
	{
		field: 'updatedAt',
		key: 'updatedAt',
		sortable: true,
		title: 'Modifié le',
		render: ({ updatedAt }) => updatedAt.toLocaleDateString('fr-FR'),
		width: 75,
	},
];
