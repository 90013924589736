import { useTable } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import OngoingProceduresTable from './OngoingProceduresTable';
import { useSession } from '../../../../../src/components/SessionProvider';
import { PermissionEnum } from '../../../../../types/permission';
import ProceduresTableHeader from '../../../components/ProceduresTableHeader';
import { ProcedureType } from '../../../types/procedure';

const Ongoing = () => {
	const { push } = useRouter();
	const { permissions } = useSession();
	const tableInstance = useTable();
	const handleTableReload = () => tableInstance.reload();

	const [procedureToEditId, setProcedureToEditId] = useState<ProcedureType['id']>();

	const closeProcedureDialog = () => {
		setProcedureToEditId(undefined);
		handleTableReload();
	};

	useEffect(() => {
		const hasUserPermissions = [PermissionEnum.SIGN_ACCESS].some(
			(permission) => permissions.find(({ code }) => permission === code)?.value,
		);

		if (!hasUserPermissions) {
			push('/error/403').catch((e) => {
				throw e;
			});
		}
	}, [permissions, push]);

	return (
		<Stack height="100%" gap={2}>
			<ProceduresTableHeader
				isProcedureDialogOpen={!!procedureToEditId}
				procedureId={procedureToEditId}
				onCloseProcedureDialog={closeProcedureDialog}
			/>

			<OngoingProceduresTable onDraftProcedureClick={(id) => setProcedureToEditId(id)} tableInstance={tableInstance} />
		</Stack>
	);
};

export default Ongoing;
