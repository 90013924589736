import { ProcedureDocumentType, SignDocumentType } from './document';
import { SignerType } from './signer';

export enum ProcedureStatusEnum {
	DRAFT = 'DRAFT',
	ONGOING = 'ONGOING',
	DECLINED = 'DECLINED',
	EXPIRED = 'EXPIRED',
	CANCELED = 'CANCELED',
	DONE = 'DONE',
}

export type UserType = {
	id: string;
	firstName?: string;
	lastName: string;
};

export type ProcedureType = {
	id: string;
	name: string;
	isPrivate: boolean;
	status: ProcedureStatusEnum;
	createdBy: UserType;
	updatedAt: Date;
	signers: SignerType[];
	comment?: string;
	documents: ProcedureDocumentType[];
};

export type ProcedureInputType = {
	id: ProcedureType['id'];
	global: SaveProcedureInput;
	signers?: SaveSignersInput;
	documents?: SignDocumentType[];
};

export type SaveProcedureInput = {
	name: ProcedureType['name'];
	isPrivate?: ProcedureType['isPrivate'];
	comment?: string;
};

export type SaveSignersInput = {
	isSignOrdered: boolean;
	signers?: SignerType[];
};

export type ProcedureFormType = Omit<ProcedureInputType, 'id'>;
