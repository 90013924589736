import { useLazyQuery } from '@apollo/client';
import { Carousel } from '@elipssolution/harfang';
import { fakerFR as faker } from '@faker-js/faker';
import { Avatar, Skeleton, Stack, Typography, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { WidgetComponentProps } from '../../../types/widget';
import { FETCH_CONTACTS, FetchContactsType } from '../../api/contact';
import { useSession } from '../../components/SessionProvider';
import { ContactType } from '../../types/contact';

// This custom list is being used instead of faker.js data because the job titles should match the industry.
const accountingJobTitles = [
	'Expert Comptable',
	'Responsable client',
	'Auditeur financier',
	'Contrôleur de gestion',
	'Analyste financier',
	'Chef comptable',
	'Directeur financier',
	'Comptable fiscaliste',
	'Comptable général',
	'Comptable fournisseurs',
	'Comptable clients',
	'Contrôleur interne',
	'Gestionnaire de paie',
	'Auditeur interne',
	'Analyste crédit',
	'Comptable analytique',
	'Chargé de recouvrement',
	'Trésorier',
	'Contrôleur financier',
	'Chef de mission comptable',
];

const CarouselWrapper = styled('div')({
	div: {
		'&.alice-carousel__prev-btn, &.alice-carousel__next-btn': {
			'& > div': {
				top: '20%',
			},
		},
	},
});

const ContactContainer = styled('div')(({ theme: { spacing } }) => ({
	flex: 1,

	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',

	padding: spacing(2),

	marginTop: spacing(2),

	'& > header': {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},

	'& > *': {
		flex: 1,
	},
}));

const AVATAR_SIZE = 100;

const Picture = styled(Avatar)(({ theme: { shadows } }) => ({
	width: AVATAR_SIZE,
	height: AVATAR_SIZE,

	boxShadow: shadows[3],
}));

const InfoWrapper = styled(Stack)(({ theme: { palette } }) => ({
	'& > div': {
		'& > .subtitle': {
			fontWeight: 400,
			fontSize: '0.875rem',
			color: palette.grey[500],
		},

		'& > .value': {
			'& > a': { color: palette.text.primary },
		},
	},
}));

const ItemSkeleton = styled(Skeleton)(({ theme: { shape } }) => ({
	flex: 1,
	height: '100%',

	borderRadius: shape.borderRadius * 2,
}));

const ContactItem = ({ contact }: { contact?: ContactType }) => {
	if (!contact) {
		return <ItemSkeleton variant="rectangular" />;
	}

	const { id, job, email, name, phoneNumber, hasPicture } = contact;

	const phoneNumberHref = `tel:${phoneNumber ?? ''}`;
	const phoneNumberHumanReadable = phoneNumber?.replace(/(.{2})/g, '$1 ').trim();

	const emailHref = `mailto:${email}`;

	const formattedName = `${name.split(' ')[0]} ${name.split(' ')[1].toUpperCase()}`;

	return (
		<ContactContainer key={id}>
			<Stack alignItems="center" gap={1}>
				<Picture alt={name} src={hasPicture ? `/contact/images?contactId=${id}` : ''}>
					{name
						.split(' ')
						.slice(0, 2)
						.map((word) => word.charAt(0))
						.join('')}
				</Picture>

				<Typography textAlign="center">{formattedName}</Typography>
			</Stack>

			<InfoWrapper direction="column" spacing={1} alignSelf="flex-start">
				{job && (
					<div>
						<Typography className="subtitle">Fonction</Typography>
						<Typography className="value" variant="body2">
							{job}
						</Typography>
					</div>
				)}
				{phoneNumber && (
					<div>
						<Typography className="subtitle">Téléphone</Typography>
						<Typography className="value" variant="body2">
							<a href={phoneNumberHref}>{phoneNumberHumanReadable}</a>
						</Typography>
					</div>
				)}
				<div>
					<Typography className="subtitle">Mail</Typography>
					<Typography className="value" variant="body2">
						<a href={emailHref}>{email}</a>
					</Typography>
				</div>
			</InfoWrapper>
		</ContactContainer>
	);
};

const WidgetContact = ({ readOnly }: WidgetComponentProps) => {
	const { customerFile } = useSession();
	const { id } = customerFile ?? {};

	const [fetchContacts] = useLazyQuery<FetchContactsType>(FETCH_CONTACTS, {
		notifyOnNetworkStatusChange: true,
	});

	const fakeData: ContactType[] = useMemo(
		() =>
			Array.from({ length: faker.number.int({ min: 1, max: 3 }) }, () => ({
				id: uuid(),
				job: faker.helpers.arrayElement(accountingJobTitles),
				name: `${faker.person.firstName()} ${faker.person.lastName()}`,
				hasPicture: false,
				email: faker.internet.email(),
				phoneNumber: faker.helpers.fromRegExp(/0[1-7]{1}[0-9]{8}/), // Intentionally not using `faker.phone.phoneNumber()` because it may generate +33 numbers (which is not consistent with our real data).
			})),
		[],
	);

	const fakeDataSource = useCallback(
		async (): Promise<{ items: ContactType[]; count: number }> =>
			new Promise((resolve, reject) => {
				try {
					resolve({
						items: fakeData,
						count: fakeData.length,
					});
				} catch (error) {
					reject(error);
				}
			}),
		[fakeData],
	);

	const dataSource = useCallback(
		async (limit: number, offset: number) => {
			const { data, error } = await fetchContacts({
				variables: {
					page: {
						limit,
						offset,
					},
				},
			});

			if (error) {
				throw error;
			}

			const {
				contacts: { items = [], count = 0 },
			} = data ?? { contacts: {} };

			return { items, count };
		},
		[fetchContacts],
	);

	const renderItem = (contact?: ContactType) => <ContactItem contact={contact} />;

	return (
		<CarouselWrapper>
			<Carousel
				key={id}
				dataSource={readOnly ? fakeDataSource : dataSource}
				itemHeight={338}
				itemWidth={200}
				renderItem={renderItem}
			/>
		</CarouselWrapper>
	);
};

export default WidgetContact;
