import { useMutation } from '@apollo/client';
import { Dialog, DialogProps } from '@elipssolution/harfang';
import { CircularProgress, Typography, styled, Divider } from '@mui/material';
import { isAfter, isBefore, isSameDay, isWithinInterval } from 'date-fns';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import VoucherInformationsSection from './VoucherInformationsSection';
import VoucherLinesSection from './VoucherLinesSection';
import { useSession } from '../../../../../src/components/SessionProvider';
import { DIALOG_CLOSE_DELAY } from '../../../../../src/utils/dialogCloseDelay';
import { generateErrorInformations } from '../../../../../utils/errorHandler';
import { findMinMaxDates } from '../../../../../utils/fiscalYears';
import { CreateVoucherType, CREATE_VOUCHER, UpdateVoucherType, UPDATE_VOUCHER } from '../../../api/voucher';
import useUnpaginatedFiscalYears from '../../../hooks/useUnpaginatedFiscalYears';
import { DirectionEnum } from '../../../types/settingTemplate';
import { TemplateType } from '../../../types/template';
import { VoucherDialogLineType, VoucherFormType, VoucherNatureEnum, VoucherType } from '../../../types/voucher';
import useTemplate from '../hooks/useTemplate';
import useVoucher from '../hooks/useVoucher';

const InfoWrapper = styled('div')(({ theme: { shape, spacing } }) => ({
	height: 36,

	display: 'flex',
	alignItems: 'center',

	padding: spacing(0, 1),

	borderRadius: shape.borderRadius * 2,
}));

const ErrorWrapper = styled(InfoWrapper)(({ theme: { palette } }) => ({
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
}));

const WarningWrapper = styled(InfoWrapper)(({ theme: { palette } }) => ({
	color: palette.warning.main,
	backgroundColor: `${palette.warning.main}1A`,
}));

const LoadingWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: spacing(2),
}));

const getFormDefaultValues = ({
	minimumDocumentDate,
	template,
	voucher,
	voucherNature,
}: {
	minimumDocumentDate?: Date;
	template?: TemplateType;
	voucher?: VoucherType;
	voucherNature?: VoucherNatureEnum;
}) => {
	const todayDate = new Date();
	let defaultDocumentDate = todayDate;

	if (minimumDocumentDate && isBefore(todayDate, minimumDocumentDate)) {
		defaultDocumentDate = minimumDocumentDate;
	}

	const { creditNote, paymentMode } = voucher ?? template ?? {};
	const { defaultEntryName = '' } = template ?? {};
	const { accountingDate, documentDate, documentNumber = '', dueDate, name = defaultEntryName } = voucher ?? {};

	const calculatedNature = creditNote ? VoucherNatureEnum.CREDIT_NOTE : VoucherNatureEnum.INVOICE;

	return {
		nature: voucherNature ?? calculatedNature,
		paymentMode: paymentMode ?? undefined,
		accountingDate: accountingDate ?? defaultDocumentDate,
		documentDate: documentDate ?? defaultDocumentDate,
		dueDate: dueDate ?? undefined,
		documentNumber,
		lines: [],
		name,
	};
};

const getTemplateLinesDefaultValues = ({
	template: { templateLines },
	voucherNature,
}: {
	template: TemplateType;
	voucherNature: VoucherNatureEnum;
}) =>
	templateLines.map(
		({
			account,
			analyticalSection,
			defaultTransactionName,
			direction,
			isAnalyticalEnabled,
			name: lineName,
			subaccount,
			subaccountPrefix,
			type,
		}) => {
			let lineDirection = direction;

			if (voucherNature === VoucherNatureEnum.CREDIT_NOTE) {
				lineDirection = direction === DirectionEnum.CREDIT ? DirectionEnum.DEBIT : DirectionEnum.CREDIT;
			}

			return {
				account: account ?? undefined,
				amount: '',
				analyticalSection: analyticalSection ?? undefined,
				description: lineName,
				direction: lineDirection,
				name: defaultTransactionName ?? '',
				subaccount: subaccount ?? undefined,
				isAnalyticalEnabled,
				subaccountPrefix,
				type,
			};
		},
	);

const getVoucherLinesDefaultValues = ({
	template: { templateLines },
	voucher: { voucherLines },
	voucherNature,
}: {
	template: TemplateType;
	voucher: VoucherType;
	voucherNature: VoucherNatureEnum;
}) =>
	voucherLines.map(
		(
			{ account, analyticalSection, creditAmount, debitAmount, id, name: lineName, subaccount, subaccountPrefix },
			index,
		) => {
			const { direction, isAnalyticalEnabled, name: templateLineName, type } = templateLines[index];

			let lineDirection = direction;

			if (voucherNature === VoucherNatureEnum.CREDIT_NOTE) {
				lineDirection = direction === DirectionEnum.CREDIT ? DirectionEnum.DEBIT : DirectionEnum.CREDIT;
			}

			return {
				amount: lineDirection === DirectionEnum.CREDIT ? creditAmount : debitAmount,
				description: templateLineName ?? '',
				direction: lineDirection,
				name: lineName,
				subaccount: subaccount ?? undefined,
				account,
				analyticalSection,
				id,
				isAnalyticalEnabled,
				subaccountPrefix,
				type,
			};
		},
	);

type EditVoucherDialogProps = {
	open: boolean;
	onClose: () => void;
	onVoucherEdit: () => void;
	templateId?: TemplateType['id'];
	voucherId?: VoucherType['id'];
};

const EditVoucherDialog = ({ open, onClose, onVoucherEdit, templateId, voucherId }: EditVoucherDialogProps) => {
	const { customerFile } = useSession();
	const { minimumEntryDate } = customerFile ?? {};

	const [error, setError] = useState<string>();
	const [isVoucherEditSuccess, setIsVoucherEditSuccess] = useState(false);
	const [editVoucherError, setEditVoucherError] = useState<string>();

	const {
		clearErrors,
		control,
		formState: { dirtyFields, isDirty, isValid },
		handleSubmit,
		reset,
		setError: setFormError,
		setValue,
		watch,
	} = useForm<VoucherFormType>();

	const { fields: lines, append: appendToLines } = useFieldArray({
		name: 'lines',
		control,
		rules: {
			minLength: 2,
		},
	});

	const formAccountingDate = watch('accountingDate');
	const formDocumentDate = watch('documentDate');
	const formLines = watch('lines');
	const formNature = watch('nature');

	const { loading: isVoucherFetchLoading, voucher } = useVoucher({ voucherId });

	const { template } = useTemplate({ templateId: voucher?.template.id ?? templateId });

	const { fiscalYears, loading: isFiscalYearsFetchLoading } = useUnpaginatedFiscalYears({ skip: !templateId });

	const { analyticalDimensionId, analyticalSectionCaption } = useMemo(() => {
		const {
			analyticalDimension: templateAnalyticalDimension,
			analyticalSectionCaption: templateAnalyticalSectionCaption,
		} = template ?? {};

		const { id: templateAnalyticalDimensionId } = templateAnalyticalDimension ?? {};

		return {
			analyticalDimensionId: templateAnalyticalDimensionId,
			analyticalSectionCaption: templateAnalyticalSectionCaption ?? 'Section',
		};
	}, [template]);

	const handleClose = useCallback(() => {
		setEditVoucherError(undefined);
		setError(undefined);

		reset({
			accountingDate: undefined,
			documentDate: undefined,
			documentNumber: undefined,
			dueDate: undefined,
			name: undefined,
			paymentMode: undefined,
		});

		onClose?.();
	}, [onClose, reset]);

	const [createVoucher, { loading: isCreateVoucherLoading }] = useMutation<CreateVoucherType>(CREATE_VOUCHER, {
		onCompleted: () => {
			setIsVoucherEditSuccess(true);
			setTimeout(() => {
				setIsVoucherEditSuccess(false);
				handleClose();
				onVoucherEdit();
			}, DIALOG_CLOSE_DELAY);
		},
		onError: (mutationError) =>
			setEditVoucherError(
				generateErrorInformations({
					error: mutationError,
					resource: 'quickentry_createVoucher',
				}).message,
			),
	});

	const [updateVoucher, { loading: isUpdateVoucherLoading }] = useMutation<UpdateVoucherType>(UPDATE_VOUCHER, {
		onCompleted: () => {
			setIsVoucherEditSuccess(true);
			setTimeout(() => {
				setIsVoucherEditSuccess(false);
				handleClose();
				onVoucherEdit();
			}, DIALOG_CLOSE_DELAY);
		},
		onError: (mutationError) =>
			setEditVoucherError(
				generateErrorInformations({
					error: mutationError,
					resource: 'quickentry_updateVoucher',
				}).message,
			),
	});

	const isBalanced =
		formLines
			?.reduce(
				(balance, { amount = '0.00', direction }) =>
					direction === DirectionEnum.CREDIT ? balance.add(amount) : balance.subtract(amount),
				numeral(0),
			)
			.value() === 0;

	const isSubaccountInLines = lines.some(({ subaccount, subaccountPrefix }) => !!(subaccount || subaccountPrefix));

	const isMutationButtonDisabled = useMemo(() => !isDirty || !isValid || !isBalanced, [isBalanced, isDirty, isValid]);
	const openedFiscalYearsBoundaries = useMemo(() => findMinMaxDates(fiscalYears), [fiscalYears]);

	const minimumDocumentDate = useMemo(() => {
		const { minDate: fiscalYearMinDate } = openedFiscalYearsBoundaries ?? {};

		// If there is a transaction on a 6/7 classed account and the minimumEntryDate is existing and after the fiscalYearMinDate
		if (
			template?.templateLines?.some(
				({ account: { code: accountCode } }) => accountCode.startsWith('6') || accountCode.startsWith('7'),
			) &&
			minimumEntryDate &&
			(!fiscalYearMinDate || isBefore(fiscalYearMinDate, minimumEntryDate))
		) {
			return minimumEntryDate;
		}

		return fiscalYearMinDate;
	}, [minimumEntryDate, openedFiscalYearsBoundaries, template?.templateLines]);

	const dialogExtraInformations = useMemo(() => {
		if (editVoucherError) {
			return [<ErrorWrapper key="error">{editVoucherError}</ErrorWrapper>];
		}

		if (isValid && !isBalanced) {
			return [<WarningWrapper key="warning">La saisie doit être équilibrée</WarningWrapper>];
		}

		return ["Tous les champs marqués d'un * sont obligatoires"];
	}, [editVoucherError, isBalanced, isValid]);

	const onSubmit = useCallback(
		(values: VoucherFormType) => {
			if (!template) return null;

			const { accountingDate, documentDate, documentNumber, dueDate, paymentMode, name, nature } = values as Omit<
				VoucherFormType,
				'paymentMode'
			> & {
				paymentMode: NonNullable<VoucherFormType['paymentMode']>;
			};

			const editedVoucher = {
				creditNote: nature === VoucherNatureEnum.CREDIT_NOTE,
				paymentModeId: paymentMode.id,
				templateId: template.id,
				voucherLines: values.lines.map(
					(
						{ account: { code: accountCode }, amount, analyticalSection, direction, id, name: lineName, subaccount },
						index,
					) => ({
						analyticalSectionId: analyticalSection?.id,
						name: lineName,
						rank: index,
						subaccountId: subaccount?.id,
						amount,
						accountCode,
						direction,
						id,
					}),
				),
				analyticalDimensionId,
				accountingDate,
				documentDate,
				dueDate,
				documentNumber,
				name,
			};

			reset(values);

			if (voucherId) {
				return updateVoucher({
					variables: {
						updateVoucherInput: { id: voucherId, ...editedVoucher },
					},
				});
			}

			return createVoucher({
				variables: {
					createVoucherInput: editedVoucher,
				},
			});
		},
		[createVoucher, reset, updateVoucher, analyticalDimensionId, template, voucherId],
	);
	const submitButtonLabel = useMemo(() => {
		if (voucher) return isVoucherEditSuccess ? 'Saisie modifiée' : 'Modifier la saisie';
		return isVoucherEditSuccess ? 'Pièce saisie' : 'Saisir';
	}, [isVoucherEditSuccess, voucher]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: 'Annuler',
				loading: isCreateVoucherLoading || isUpdateVoucherLoading,
				onClick: handleClose,
			},
			{
				disabled: isMutationButtonDisabled,
				error: !!editVoucherError,
				label: submitButtonLabel,
				loading: isCreateVoucherLoading || isUpdateVoucherLoading,
				onClick: handleSubmit(onSubmit),
				success: isVoucherEditSuccess,
				variant: 'contained',
			},
		],
		[
			isCreateVoucherLoading,
			isUpdateVoucherLoading,
			handleClose,
			isMutationButtonDisabled,
			editVoucherError,
			submitButtonLabel,
			handleSubmit,
			onSubmit,
			isVoucherEditSuccess,
		],
	);

	const validateDocumentDate = (value?: Date): boolean => {
		if (!value) {
			clearErrors('documentDate');
			return true;
		}

		if (!isSameDay(value, formAccountingDate) && isAfter(value, formAccountingDate)) {
			setFormError('documentDate', { message: 'La date doit être antérieure à la date de comptabilisation' });
			return false;
		}
		clearErrors('documentDate');
		return true;
	};

	const validateAccountingDate = (value?: Date): boolean => {
		if (!value || !fiscalYears) {
			clearErrors('accountingDate');
			return true;
		}

		const fiscalYear = fiscalYears?.find(({ startDate, endDate }) =>
			isWithinInterval(value, { start: startDate, end: endDate }),
		);

		if (!fiscalYear) {
			setFormError('accountingDate', { message: "Aucun exercice en cours n'inclut cette date" });
			return false;
		}
		if (fiscalYear.isClosed) {
			setFormError('accountingDate', { message: "L'exercice est clôturé pour cette date" });
			return false;
		}
		clearErrors('accountingDate');
		return true;
	};

	const dialogTitle = useMemo(() => {
		if (voucher) {
			return voucher.name;
		}

		if (isVoucherFetchLoading) {
			return 'Chargement de la pièce...';
		}

		return 'Saisir une pièce';
	}, [isVoucherFetchLoading, voucher]);

	// Initialize the form values
	useEffect(() => {
		if (error) return;

		const defaultValues = getFormDefaultValues({
			voucherNature: formNature,
			minimumDocumentDate,
			template,
			voucher,
		});

		reset(defaultValues);

		const linesToAppend: VoucherDialogLineType[] = [] as VoucherDialogLineType[];

		if (voucher && template) {
			linesToAppend.push(
				...getVoucherLinesDefaultValues({
					voucherNature: formNature,
					template,
					voucher,
				}),
			);
		} else if (template) {
			linesToAppend.push(
				...getTemplateLinesDefaultValues({
					voucherNature: formNature,
					template,
				}),
			);
		}

		appendToLines(linesToAppend);
	}, [appendToLines, reset, error, formNature, minimumDocumentDate, template, voucher]);

	// Synchronize the date fields while they are not dirty
	useEffect(() => {
		if (!templateId || !formDocumentDate) return;

		!dirtyFields.accountingDate && setValue('accountingDate', formDocumentDate);

		if (isSubaccountInLines && !dirtyFields.dueDate) setValue('dueDate', formDocumentDate);
	}, [setValue, dirtyFields, formDocumentDate, isSubaccountInLines, templateId]);

	return (
		<Dialog
			actionsDialog={actionsDialog}
			error={error}
			extraInfos={dialogExtraInformations}
			maxWidth="lg"
			open={open}
			onClose={handleClose}
			title={dialogTitle}
			fullWidth
		>
			{isVoucherFetchLoading || isFiscalYearsFetchLoading ? (
				<LoadingWrapper>
					<CircularProgress size={36} color="inherit" />
					<Typography>Chargement en cours...</Typography>
				</LoadingWrapper>
			) : (
				<>
					<VoucherInformationsSection
						control={control}
						isDueDateVisible={isSubaccountInLines}
						validateDocumentDate={validateDocumentDate}
						validateAccountingDate={validateAccountingDate}
						openedFiscalYearsBoundaries={{
							...openedFiscalYearsBoundaries,
							minDate: minimumDocumentDate,
						}}
					/>
					<Divider />
					<VoucherLinesSection
						analyticalDimensionId={analyticalDimensionId}
						analyticalSectionCaption={analyticalSectionCaption}
						isSubaccountInLines={isSubaccountInLines}
						control={control}
						watch={watch}
						clearErrors={clearErrors}
						setError={setFormError}
						lines={lines}
						isWarning={isValid && !isBalanced}
					/>
				</>
			)}
		</Dialog>
	);
};

export default EditVoucherDialog;
