import { gql } from '@apollo/client';

import { SignDocumentType } from '../types/document';

export type UpdateDocumentsType = {
	sign_updateDocuments: { id: SignDocumentType['id'] };
};

export const UPDATE_DOCUMENTS = gql`
	mutation Sign_updateDocuments($saveDocumentsInput: sign_SaveDocumentsInput!) {
		sign_updateDocuments(saveDocumentsInput: $saveDocumentsInput) {
			id
		}
	}
`;
