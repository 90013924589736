import { FileUpload } from '@elipssolution/harfang';
import { Stack, styled } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Control, Controller } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import UploadedDocumentsTable from './UploadedDocumentsTable';
import { DocumentFieldsType, DocumentFieldTypeEnum, SignDocumentType } from '../../../types/document';
import { ProcedureFormType, ProcedureType } from '../../../types/procedure';
import { SignerType } from '../../../types/signer';

const MAX_FILES = 10;
const MAX_FILE_SIZE = 10; // Mo

const FormWrapper = styled(Stack)(({ theme: { spacing } }) => ({
	alignItems: 'flex-start',
	gap: spacing(2),
}));

type DocumentsFormProps = {
	control: Control<ProcedureFormType>;
	signers: SignerType[];
	procedureId?: ProcedureType['id'];
};

const DocumentsForm = ({ control, signers, procedureId }: DocumentsFormProps) => (
	<FormWrapper>
		<Controller
			name="documents"
			control={control}
			render={({ field: { onChange, value } }) => {
				const handleDocumentUploadChange = (documents: File[]) => {
					const newDocuments = documents.map((document) => ({ id: uuid(), document, fields: [] }));
					onChange([...(value ?? []), ...newDocuments]);
				};

				const onDeleteDocument = (id: SignDocumentType['id']) => {
					onChange((value ?? []).filter(({ id: idToRemove }) => id !== idToRemove));
				};

				const handleSign = (index: number, fields: DocumentFieldsType) => {
					if (value) {
						const documentsCopy = [...value];
						const [documentToUpdate] = documentsCopy.splice(index, 1);
						const texts = (fields?.texts ?? []).map(({ x, y, content, documentId, ...rest }) => ({
							type: DocumentFieldTypeEnum.READ_ONLY_TEXT,
							x,
							y,
							text: content,
							...rest,
						}));

						const mentions = (fields?.mentions ?? []).map(({ x, y, mention, signatoryId, documentId, ...rest }) => ({
							type: DocumentFieldTypeEnum.MENTION,
							x,
							y,
							mention: mention.replace('JJ-MM-AAAA', '%date%'),
							signerYousignId: signatoryId,
							...rest,
						}));
						const signatures = fields.signatures.map(({ x, y, signatoryId, documentId, ...rest }) => ({
							type: DocumentFieldTypeEnum.SIGNATURE,
							x,
							y,
							signerYousignId: signatoryId,
							...rest,
						}));
						documentToUpdate.fields = [...texts, ...signatures, ...mentions];
						documentsCopy.splice(index, 0, documentToUpdate);
						onChange(documentsCopy);
					}
				};
				return (
					<>
						{value?.length !== MAX_FILES && (
							<div style={{ height: (value?.length ?? -1) > 0 ? 100 : 300, width: '100%' }}>
								<FileUpload
									onChange={handleDocumentUploadChange}
									maxFileSize={MAX_FILE_SIZE}
									maxFiles={MAX_FILES - (value?.length ?? 0)}
									showInformations
									acceptedTypes={{
										'application/pdf': ['.pdf'],
									}}
								/>
							</div>
						)}
						<DndProvider backend={HTML5Backend} context={window}>
							{value && value?.length > 0 && (
								<UploadedDocumentsTable
									uploadedDocuments={value}
									handleOrderDocuments={onChange}
									onDeleteDocument={onDeleteDocument}
									procedureId={procedureId}
									signers={signers}
									onSign={handleSign}
								/>
							)}
						</DndProvider>
					</>
				);
			}}
		/>
	</FormWrapper>
);
export default DocumentsForm;
