import { Icon, TextField } from '@elipssolution/harfang';
import { mdiMagnify } from '@mdi/js';
import { FormControlLabel, InputAdornment, styled, Switch } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Control, Controller, useWatch } from 'react-hook-form';

import SelectedSignerItem, { ItemTypes } from './SelectedSignerItem';
import { SignContactType } from '../../../types/contact';
import { ProcedureFormType } from '../../../types/procedure';
import { SignerType } from '../../../types/signer';

const StyledListContainer = styled('div')(({ theme: { typography, shape, shadows, palette } }) => ({
	...typography.body2,
	boxShadow: shadows[1],
	borderRadius: shape.borderRadius * 2,
	border: `1px solid ${palette.divider}`,
	width: '40%',
	height: 400,
	display: 'flex',
	flexDirection: 'column',
}));

const StyledListFooter = styled('div')(({ theme: { spacing, shape } }) => ({
	padding: spacing(1),
	boxShadow:
		'0px -2px 1px -1px rgba(0, 0, 0, 0.2), 0px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px -1px 3px 0px rgba(0, 0, 0, 0.12)',
	borderBottomLeftRadius: shape.borderRadius * 2,
	borderBottomRightRadius: shape.borderRadius * 2,
}));

const StyledBody = styled('div')({
	flex: '1 1 auto',
	overflow: 'hidden',
	overflowY: 'auto',
});
const StyledListHeader = styled('div')(({ theme: { spacing, shadows, shape } }) => ({
	padding: spacing(1),
	boxShadow: shadows[1],
	borderTopLeftRadius: shape.borderRadius * 2,
	borderTopRightRadius: shape.borderRadius * 2,
}));

type SelectedSignersProps = {
	chosenContacts: SignerType[];
	control: Control<ProcedureFormType>;
	handleUnselectSigner: (contactId: SignContactType['id'], email: SignContactType['email']) => void;
	handleOrderSigners: (contacts: SignerType[]) => void;
};

const SelectedSigners = ({
	handleUnselectSigner,
	handleOrderSigners,
	chosenContacts,
	control,
}: SelectedSignersProps) => {
	const [displayedContacts, setDisplayedContacts] = useState<SignerType[]>([]);
	const [search, setSearch] = useState<string>();

	const isSignOrdered = useWatch({ control, name: 'signers.isSignOrdered' });

	const unselectContact = useCallback(
		(index: SignContactType['id'], email: SignContactType['email']) => {
			handleUnselectSigner(index, email);
			setSearch(undefined);
		},
		[handleUnselectSigner],
	);

	const findContactIndex = useCallback(
		(id: SignContactType['id']) => chosenContacts.findIndex(({ id: chosenContactId }) => chosenContactId === id),

		[chosenContacts],
	);

	const moveContact = useCallback(
		(id: SignContactType['id'], atIndex: number) => {
			const index = findContactIndex(id);
			const chosenContactsCopy = [...chosenContacts];
			const [contactToMove] = chosenContactsCopy.splice(index, 1);
			chosenContactsCopy.splice(atIndex, 0, contactToMove);
			handleOrderSigners(chosenContactsCopy);
		},
		[chosenContacts, findContactIndex, handleOrderSigners],
	);

	const [, drop] = useDrop(() => ({ accept: ItemTypes.CONTACT }));

	const listBody = useMemo(
		() => (
			<div ref={isSignOrdered ? drop : null}>
				{(isSignOrdered ? chosenContacts : displayedContacts).map((contact) => (
					<SelectedSignerItem
						key={contact.id}
						signer={contact}
						moveContact={moveContact}
						findContactIndex={findContactIndex}
						isOrderingEnabled={isSignOrdered}
						onRowClick={unselectContact}
					/>
				))}
			</div>
		),
		[chosenContacts, displayedContacts, drop, findContactIndex, isSignOrdered, moveContact, unselectContact],
	);

	const handleChangeSearch = (searchValue?: string) => {
		setSearch(searchValue);
		if (searchValue) {
			setDisplayedContacts(
				chosenContacts.filter((contact) => {
					const fullName = `${contact.firstName} ${contact.lastName}`
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '');
					return fullName.includes(
						searchValue
							.toLowerCase()
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/g, ''),
					);
				}),
			);
		} else setDisplayedContacts(chosenContacts);
	};

	useEffect(() => {
		setDisplayedContacts(chosenContacts);
	}, [chosenContacts]);

	return (
		<StyledListContainer>
			<StyledListHeader>
				<TextField
					disabled={isSignOrdered || chosenContacts.length === 0}
					label="Signataires ajoutés"
					size="small"
					value={isSignOrdered ? '' : search}
					onChange={handleChangeSearch}
					endAdornment={
						<InputAdornment position="end">
							<Icon path={mdiMagnify} size="small" />
						</InputAdornment>
					}
				/>
			</StyledListHeader>

			<StyledBody>{listBody}</StyledBody>

			<StyledListFooter>
				<Controller
					defaultValue={false}
					name="signers.isSignOrdered"
					control={control}
					render={({ field: { onChange, value, ...field } }) => {
						const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
							onChange(event.target.checked);
						};
						return (
							<FormControlLabel
								control={<Switch {...field} checked={value} onChange={handleChange} />}
								label="Définir un ordre de signature"
							/>
						);
					}}
				/>
			</StyledListFooter>
		</StyledListContainer>
	);
};

export default SelectedSigners;
