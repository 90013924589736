import { DocumentMapperType, SignDocumentType } from '../../../types/document';

const areFieldsEqual = (fields1: SignDocumentType['fields'], fields2: SignDocumentType['fields']) =>
	fields1?.length === fields2?.length &&
	fields1?.every((field, index) => JSON.stringify(field) === JSON.stringify(fields2?.[index]));

export const findAddedAndUpdatedDocuments = (newDocuments: SignDocumentType[], initialDocuments: SignDocumentType[]) =>
	newDocuments.reduce(
		(
			acc: {
				documentsToCreate: SignDocumentType[];
				documentsToUpdate: SignDocumentType[];
			},
			document,
			index,
		) => {
			const { id, fields } = document;
			const afterId = newDocuments[index - 1]?.id;

			const initialDocumentIndex = initialDocuments.findIndex(({ id: initialDocumentId }) => initialDocumentId === id);
			const initialAfterId = initialDocuments[initialDocumentIndex - 1]?.id;
			const isEqual = areFieldsEqual(initialDocuments[initialDocumentIndex]?.fields, fields);
			if (initialDocumentIndex === -1) {
				acc.documentsToCreate.push({ ...document, afterId, hasOrderChanged: false });
			} else if (afterId !== initialAfterId) {
				acc.documentsToUpdate.push({ id, ...(isEqual ? { afterId } : { fields, afterId }), hasOrderChanged: true });
			} else if (!isEqual) {
				acc.documentsToUpdate.push({ id, fields, afterId, hasOrderChanged: false });
			}

			return acc;
		},
		{ documentsToCreate: [], documentsToUpdate: [] },
	);

export const getDocumentId = (documentsIdsMapper: DocumentMapperType[], id: SignDocumentType['id']) =>
	documentsIdsMapper?.find(({ id: yousignId }) => yousignId === id)?.yousignId ?? id;
