import { useLazyQuery } from '@apollo/client';
import {
	Table,
	IconButton,
	Icon,
	TableColumnType,
	TableOrderByType,
	Tooltip,
	TableInstance,
	SelectFilterDefinitionType,
	FilterMenuOutputType,
} from '@elipssolution/harfang';
import { mdiLock, mdiEarth, mdiDelete, mdiAlertCircle } from '@mdi/js';
import { Stack, styled } from '@mui/material';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { useCallback, useMemo, useState, MouseEvent } from 'react';

import DeleteContactDialog from './DeleteContactDialog';
import { emailPattern } from '../../../utils/emailPattern';
import { FetchSignContactsType, FETCH_SIGN_CONTACTS } from '../api/contact';
import { SignContactType } from '../types/contact';

const ErrorBox = styled('div')<{ severity: 'error' | 'warning' }>(
	({ theme: { palette, spacing, shape }, severity }) => {
		const backgroundColor = {
			error: `${palette.error.main}3A`,
			warning: `${palette.warning.main}3A`,
		}[severity];

		return {
			backgroundColor,
			padding: spacing(0.4, 0.8),
			display: 'flex',
			flexDirection: 'row',
			gap: spacing(0.7),
			borderRadius: shape.borderRadius,
			color: palette[severity].main,
		};
	},
);

const MissingInformation = () => (
	<Tooltip content="L'information est manquante">
		<ErrorBox severity="error">
			<Icon path={mdiAlertCircle} size="small" color="error" />
			Manquant
		</ErrorBox>
	</Tooltip>
);

const IncorrectlyFormatted = () => (
	<Tooltip content="Le format n'est pas bon">
		<ErrorBox severity="warning">
			<Icon path={mdiAlertCircle} size="small" color="warning" />
			Incorrect
		</ErrorBox>
	</Tooltip>
);

const phoneUtil = PhoneNumberUtil.getInstance();

const evaluatePhoneNumber = (phoneNumber?: SignContactType['phoneNumber']) => {
	if (!phoneNumber) {
		return <MissingInformation />;
	}

	try {
		const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);

		if (!phoneUtil.isValidNumber(parsedNumber)) {
			return <IncorrectlyFormatted />;
		}

		return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
	} catch (error) {
		return <IncorrectlyFormatted />;
	}
};

const evaluateEmail = (email: SignContactType['email']) =>
	!emailPattern.test(email) ? <IncorrectlyFormatted /> : email;

const baseColumns: TableColumnType<SignContactType>[] = [
	{
		key: 'isPrivate',
		field: 'isPrivate',
		width: 40,
		flexGrow: 0,
		align: 'center',
		render: ({ isPrivate }) => (
			<Tooltip content={isPrivate ? 'Privé' : 'Public'}>
				<Stack alignItems="center">
					<Icon path={isPrivate ? mdiLock : mdiEarth} />
				</Stack>
			</Tooltip>
		),
	},
	{
		key: 'name',
		field: 'lastName',
		width: 150,
		title: 'Nom',
		sortable: true,
		render: ({ firstName, lastName }) => `${lastName} ${firstName}`,
	},
	{
		key: 'email',
		field: 'email',
		width: 200,
		title: 'Email',
		sortable: true,
		render: ({ email }) => evaluateEmail(email),
	},
	{
		key: 'phoneNumber',
		field: 'phoneNumber',
		width: 100,
		title: 'Téléphone',
		render: ({ phoneNumber }) => evaluatePhoneNumber(phoneNumber),
	},
];

type SignContactsFilterType = {
	isPrivate: SelectFilterDefinitionType<boolean>;
};

const contactsFilters: SignContactsFilterType = {
	isPrivate: {
		label: 'Visibilité',
		type: 'select',
		options: [true, false],
		renderOption: (isPrivate) => (isPrivate ? 'Privé' : 'Public'),
		renderValue: (isPrivate) => (isPrivate ? 'Privé' : 'Public'),
	},
};

type ContactsTableProps = {
	onContactSelection: (contact: SignContactType) => void;
	tableInstance: TableInstance;
};

const ContactsTable = ({ onContactSelection, tableInstance }: ContactsTableProps) => {
	const [fetchSignContacts] = useLazyQuery<FetchSignContactsType>(FETCH_SIGN_CONTACTS);

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [contactToDelete, setContactToDelete] = useState<SignContactType>();

	const signContactsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SignContactType>,
			filters?: FilterMenuOutputType<SignContactsFilterType>,
		): Promise<{
			count: number;
			items: SignContactType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error: queryError } = await fetchSignContacts({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
					filters,
					...(orderBy && { orderBy: { field, order } }),
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				sign_contacts: { count = 0, items = [] },
			} = data ?? {
				sign_contacts: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSignContacts],
	);

	const handleDeleteContactDialogClose = useCallback(
		(params?: { refreshTable?: boolean }) => {
			if (params?.refreshTable) {
				tableInstance.reload();
			}

			setIsDeleteDialogOpen(false);
			setTimeout(() => setContactToDelete(undefined), 100);
		},
		[tableInstance],
	);

	const handleDeleteContactDialogOpen = useCallback(
		(event: MouseEvent<HTMLButtonElement>, contact: SignContactType) => {
			event.stopPropagation();

			setContactToDelete(contact);
			setIsDeleteDialogOpen(true);
		},
		[],
	);

	const columns: TableColumnType<SignContactType>[] = useMemo(
		() => [
			...baseColumns,
			{
				key: 'actions',
				width: 40,
				flexGrow: 0,
				align: 'center',
				render: (contact) => (
					<IconButton onClick={(event) => handleDeleteContactDialogOpen(event, contact)}>
						<Icon path={mdiDelete} />
					</IconButton>
				),
			},
		],
		[handleDeleteContactDialogOpen],
	);

	return (
		<>
			<Table<SignContactType, SignContactsFilterType>
				columns={columns}
				dataSource={signContactsDataSource}
				filters={contactsFilters}
				onRowClick={onContactSelection}
				style={{
					height: '100%',
				}}
				table={tableInstance}
				title="Contacts"
				enableSearch
			/>

			<DeleteContactDialog
				isOpen={isDeleteDialogOpen}
				onClose={handleDeleteContactDialogClose}
				contact={contactToDelete}
			/>
		</>
	);
};

export default ContactsTable;
