import { useQuery } from '@apollo/client';
import { CircularProgress, Icon, IconButton } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { Stack, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { getYear } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import { FETCH_SIGNER_KPI, FetchSignerKpiType } from '../api/signer';
import { useSession } from '../../../src/components/SessionProvider';

const SignaturesInfosContainer = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: spacing(2),
	borderRadius: shape.borderRadius * 2,
	border: `1px solid ${palette.divider}`,
	padding: spacing(1),
	minWidth: 200,

	'&.error': {
		backgroundColor: `${palette.error.main}1A`,
		borderColor: palette.error.main,
	},
}));

const FiscalYearContainer = styled('div')(({ theme: { spacing, palette, shape, shadows } }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: spacing(0.5),
	gap: spacing(1),
	borderRadius: shape.borderRadius * 2,
	boxShadow: shadows[2],
	backgroundColor: palette.primary.main,
}));
const CURRENT_YEAR = getYear(new Date());

const SignaturesKpi = () => {
	const { customerFile: sessionCustomerFile } = useSession();

	const minYear = useMemo(() => sessionCustomerFile ? getYear(new Date(sessionCustomerFile?.startDate)) : CURRENT_YEAR - 5, [])

	const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR);

	const { data, error, loading } = useQuery<FetchSignerKpiType>(FETCH_SIGNER_KPI, {
		variables: {
			year: selectedYear,
		},
		skip: !selectedYear,
	});

	const { sign_signerKpi } = data || {};
	const { signers = 0 } = sign_signerKpi || {};

	const navigateToPreviousYear = useCallback(() => setSelectedYear((prevYear) => prevYear - 1), []);
	const navigateToNextYear = useCallback(() => setSelectedYear((prevYear) => prevYear + 1), []);

	return (
		<SignaturesInfosContainer className={clsx({ error: !!error })}>
			<FiscalYearContainer>
				<IconButton onClick={navigateToPreviousYear} size="small" disabled={selectedYear === minYear}>
					<Icon path={mdiChevronLeft} />
				</IconButton>

				<Typography>{selectedYear}</Typography>

				<IconButton onClick={navigateToNextYear} size="small" disabled={selectedYear === CURRENT_YEAR}>
					<Icon path={mdiChevronRight} />
				</IconButton>
			</FiscalYearContainer>

			{error && (
				<Stack paddingLeft={2} paddingRight={2}>
					<Icon path={mdiAlertCircle} color="error" />
				</Stack>
			)}

			{!error && (
				<Stack flexDirection="row" gap={1} alignItems="center">
					{loading ? <CircularProgress size="small" /> : <Typography fontWeight={800}>{signers}</Typography>}
					<Typography>signatures</Typography>
				</Stack>
			)}
		</SignaturesInfosContainer>
	);
};

export default SignaturesKpi;
