import { gql } from '@apollo/client';

import { SignerType } from '../types/signer';

export const SIGNER_FIELDS = `
    firstName
	lastName
	email
`;

// QUERIES

export type FetchSignersInOngoingOrDraftProceduresType = {
	sign_signersInOngoingOrDraftProcedures: {
		items: SignerType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_SIGNERS_IN_ONGOING_OR_DRAFT_PROCEDURES = gql`
	query Sign_signersInOngoingOrDraftProcedures($page: Page!, $search: String) {
		sign_signersInOngoingOrDraftProcedures(page: $page, search: $search) {
			items {
				${SIGNER_FIELDS}
			}
			count
			limit
			offset
		}
	}
`;

export type FetchSignersInCanceledProceduresType = {
	sign_signersInCanceledProcedures: {
		items: SignerType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_SIGNERS_IN_CANCELED_PROCEDURES = gql`
	query Sign_signersInCanceledProcedures($page: Page!, $search: String) {
		sign_signersInCanceledProcedures(page: $page, search: $search) {
			items {
				${SIGNER_FIELDS}
			}
			count
			limit
			offset
		}
	}
`;

export type FetchSignersInDeclinedProceduresType = {
	sign_signersInDeclinedProcedures: {
		items: SignerType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_SIGNERS_IN_DECLINED_PROCEDURES = gql`
	query Sign_signersInDeclinedProcedures($page: Page!, $search: String) {
		sign_signersInDeclinedProcedures(page: $page, search: $search) {
			items {
				${SIGNER_FIELDS}
			}
			count
			limit
			offset
		}
	}
`;

export type FetchSignersInFinishedProceduresType = {
	sign_signersInFinishedProcedures: {
		items: SignerType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_SIGNERS_IN_FINISHED_PROCEDURES = gql`
	query Sign_signersInFinishedProcedures($page: Page!, $search: String) {
		sign_signersInFinishedProcedures(page: $page, search: $search) {
			items {
				${SIGNER_FIELDS}
			}
			count
			limit
			offset
		}
	}
`;

export type FetchSignerKpiType = {
	sign_signerKpi: {
		signers: number;
	};
};

export const FETCH_SIGNER_KPI = gql`
	query Sign_signerKpi($year: Int!) {
		sign_signerKpi(year: $year) {
			signers
		}
	}
`;
