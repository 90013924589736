import { Icon } from '@elipssolution/harfang';
import { mdiEarth, mdiLock } from '@mdi/js';
import { ListItemText, styled } from '@mui/material';

import { SignContactType } from '../../../types/contact';

const StyledContactInfoContainer = styled('div')(({ theme: { spacing } }) => ({
	padding: spacing(0.5, 2),
	gap: spacing(3),
	display: 'flex',
	alignItems: 'center',
}));
const StyledListItemText = styled(ListItemText)({
	textAlign: 'left',
});

const ContactItem = ({
	isPrivate,
	firstName,
	lastName,
	email,
	renderPrivacyIcon,
}: Pick<SignContactType, 'firstName' | 'lastName' | 'email'> & {
	renderPrivacyIcon?: boolean;
	isPrivate?: boolean;
}) => (
	<StyledContactInfoContainer>
		{renderPrivacyIcon && <Icon path={isPrivate ? mdiLock : mdiEarth} size="small" />}

		<StyledListItemText
			primary={`${firstName}
			${lastName}`}
			secondary={email}
		/>
	</StyledContactInfoContainer>
);
export default ContactItem;
