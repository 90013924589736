import { useLazyQuery } from '@apollo/client';
import {
	AutocompleteFilterDefinitionType,
	FilterMenuOutputType,
	SelectFilterDefinitionType,
	Table,
	TableOrderByType,
} from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { FETCH_DECLINED_PROCEDURES, FetchDeclinedProceduresType } from '../../../api/procedure';
import { FETCH_SIGNERS_IN_DECLINED_PROCEDURES, FetchSignersInDeclinedProceduresType } from '../../../api/signer';
import ProcedureConsultationDialog from '../../../components/ProcedureConsultationDialog';
import { ProcedureType } from '../../../types/procedure';
import { SignerType } from '../../../types/signer';
import { baseProcedureColumns } from '../../../utils/procedureBaseColumns';
import { getSignerFilter } from '../../../utils/renderSigner';

type ProcedureFilterType = {
	isPrivate: SelectFilterDefinitionType<boolean>;
	signer: AutocompleteFilterDefinitionType<SignerType>;
};

const DeclinedProceduresTable = () => {
	const [procedureConsultationId, setProcedureConsultationId] = useState<ProcedureType['id']>();

	const [fetchDeclinedProcedures] = useLazyQuery<FetchDeclinedProceduresType>(FETCH_DECLINED_PROCEDURES);
	const [fetchSignersInDeclinedProcedures] = useLazyQuery<FetchSignersInDeclinedProceduresType>(
		FETCH_SIGNERS_IN_DECLINED_PROCEDURES,
	);

	const proceduresDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<ProcedureType>,
			filters?: FilterMenuOutputType<ProcedureFilterType>,
		): Promise<{
			count: number;
			items: ProcedureType[];
		}> => {
			const { field, order } = orderBy || {};
			const { signer, ...restFilters } = filters ?? {};

			const { data, error } = await fetchDeclinedProcedures({
				variables: {
					...(!!filters && {
						filter: {
							...restFilters,
							...(signer && {
								signerFirstName: { eq: signer.eq?.firstName },
								signerLastName: { eq: signer.eq?.lastName },
							}),
						},
					}),
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				sign_declinedProcedures: { count = 0, items = [] },
			} = data ?? {
				sign_declinedProcedures: {},
			};

			return {
				count,
				items: items.map(({ updatedAt, ...rest }) => ({
					...rest,
					updatedAt: new Date(updatedAt),
				})),
			};
		},
		[fetchDeclinedProcedures],
	);

	const signersInDeclinedProceduresDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: SignerType[];
		}> => {
			const { data, error } = await fetchSignersInDeclinedProcedures({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				sign_signersInDeclinedProcedures: { count = 0, items = [] },
			} = data ?? {
				sign_signersInDeclinedProcedures: {},
			};

			return { count, items };
		},
		[fetchSignersInDeclinedProcedures],
	);

	const filters: ProcedureFilterType = useMemo(
		() => ({
			isPrivate: {
				label: 'Visibilité',
				type: 'select',
				options: [true, false],
				renderOption: (value) => (value ? 'Privée' : 'Publique'),
				renderValue: (value) => (value ? 'Privée' : 'Publique'),
			},
			signer: getSignerFilter(signersInDeclinedProceduresDataSource),
		}),
		[signersInDeclinedProceduresDataSource],
	);

	const handleRowClick = useCallback(
		({ id: selectedProcedureId }: ProcedureType) => setProcedureConsultationId(selectedProcedureId),
		[],
	);

	const handleProcedureViewDialogClose = useCallback(() => setProcedureConsultationId(undefined), []);

	return (
		<Stack height="100%" width="100%" flex={3}>
			<Table<ProcedureType, ProcedureFilterType>
				columns={baseProcedureColumns}
				dataSource={proceduresDataSource}
				filters={filters}
				style={{
					height: '100%',
				}}
				onRowClick={handleRowClick}
				title="Procédures rejetées"
				enableSearch
			/>

			<ProcedureConsultationDialog procedureId={procedureConsultationId} onClose={handleProcedureViewDialogClose} />
		</Stack>
	);
};

export default DeclinedProceduresTable;
