import { SignContactType } from './contact';
import { SignerType } from './signer';

export enum DocumentFieldTypeEnum {
	SIGNATURE = 'SIGNATURE',
	MENTION = 'MENTION',
	READ_ONLY_TEXT = 'READ_ONLY_TEXT',
}

export type DocumentFieldsType = {
	texts?: (DocumentFieldPropertiesType & { content: string })[];
	signatures: (DocumentFieldPropertiesType & { signatoryId: SignContactType['id'] })[];
	mentions?: (DocumentFieldPropertiesType & { signatoryId: SignContactType['id']; mention: string })[];
};
export type DocumentFieldPropertiesType = {
	page: number;
	x: number;
	y: number;
	width: number;
	height: number;
	documentId: string;
};

export type DocumentFieldType = Omit<DocumentFieldPropertiesType, 'documentId' | 'x' | 'y'> & {
	id?: string;
	type: DocumentFieldTypeEnum;
	signerYousignId?: SignContactType['id'];
	text?: string;
	x: number;
	y: number;
	mention?: string;
};

export type SignDocumentType = {
	document?: File;
	id: string;
	fields?: DocumentFieldType[];
	filename?: string;
	afterId?: string;
	hasOrderChanged?: boolean;
};

export type ProcedureDocumentType = {
	id: string;
	filename: string;
	fields: (Pick<DocumentFieldType, 'type' | 'id' | 'page' | 'x' | 'y' | 'width' | 'height' | 'text' | 'mention'> & {
		signer: { id: string; contact: SignContactType };
	})[];
	signers: SignerType[];
};

export type DocumentMapperType = { id: SignDocumentType['id']; yousignId: string };
