import { NetworkStatus, useMutation } from '@apollo/client';
import { ConfirmationDialog, DialogProps, Icon, TableColumnType, TableInstance } from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { Box, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import useSubmittedOrValidatedVouchersDataSource from './hooks/useSubmittedOrValidatedVouchersDataSource';
import VoucherTable from './VoucherTable';
import { DIALOG_CLOSE_DELAY } from '../../../../src/utils/dialogCloseDelay';
import { RemoveVoucherType, REMOVE_VOUCHER, ValidateVoucherType, VALIDATE_VOUCHER } from '../../api/voucher';
import useSubmittedOrValidatedActionColumn from '../../hooks/useSubmittedOrValidatedActionColumn';
import { VoucherType } from '../../types/voucher';

const columns: TableColumnType<VoucherType>[] = [
	{
		field: 'template',
		key: 'template',
		render: ({ template: { name } }) => name,
		title: 'Modèle',
		width: 150,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Libellé de la pièce',
		width: 250,
	},
	{
		field: 'documentNumber',
		key: 'documentNumber',
		sortable: true,
		title: 'Référence de la pièce',
		width: 170,
	},
	{
		field: 'accountingDate',
		key: 'accountingDate',
		render: ({ accountingDate }) => accountingDate.toLocaleDateString('fr-FR'),
		sortable: true,
		title: 'Comptabilisé le',
		width: 130,
	},
	{
		align: 'right',
		field: 'amount',
		key: 'amount',
		render: ({ amount }) => (
			<Stack paddingRight={1}>
				<FormattedNumber value={+(amount ?? 0)} style="currency" currency="EUR" />
			</Stack>
		),
		sortable: true,
		title: 'Montant',
		width: 125,
	},
];

type SubmittedOrValidatedVoucherTableProps = {
	onVoucherSelection: (voucherId: VoucherType['id']) => void;
	onVoucherValidation: () => void;
	tableInstance: TableInstance;
};

const SubmittedOrValidatedVoucherTable = ({
	onVoucherSelection,
	onVoucherValidation,
	tableInstance,
}: SubmittedOrValidatedVoucherTableProps) => {
	const [voucherToRemoveId, setVoucherToRemoveId] = useState<VoucherType['id']>();

	const handleRemoveConfirmationDialogClose = () => setVoucherToRemoveId(undefined);

	const [validateVoucher] = useMutation<ValidateVoucherType>(VALIDATE_VOUCHER, {
		onCompleted: () => setTimeout(onVoucherValidation, DIALOG_CLOSE_DELAY),
	});

	const [removeVoucher, { data: removeVoucherData, loading: isVoucherRemovalLoading, reset }] =
		useMutation<RemoveVoucherType>(REMOVE_VOUCHER, {
			onCompleted: () => {
				setTimeout(() => {
					handleRemoveConfirmationDialogClose();

					reset();

					tableInstance.reload();
				}, DIALOG_CLOSE_DELAY);
			},
		});

	const handleRemove = useCallback(
		() =>
			removeVoucher({
				variables: {
					id: voucherToRemoveId,
				},
			}),
		[removeVoucher, voucherToRemoveId],
	);

	const handleValidate = useCallback(
		(id: VoucherType['id']) =>
			validateVoucher({
				variables: {
					id,
				},
			}),
		[validateVoucher],
	);

	const actionColumn = useSubmittedOrValidatedActionColumn<VoucherType>({
		onValidate: handleValidate,
		onRemove: (id: VoucherType['id']) => setVoucherToRemoveId(id),
	});

	const tableColumns = useMemo(() => [...columns, actionColumn], [actionColumn]);

	const {
		dataSource: submittedOrValidatedVouchersDataSource,
		hasItems: hasSubmittedOrValidatedVouchers,
		networkStatus,
	} = useSubmittedOrValidatedVouchersDataSource();

	const handleVoucherSelection = ({ id }: VoucherType) => onVoucherSelection(id);

	const removeConfirmationDialogActions = useMemo(
		() =>
			[
				{
					label: 'Annuler',
					loading: isVoucherRemovalLoading,
					onClick: handleRemoveConfirmationDialogClose,
				},
				{
					color: 'error',
					label: 'Supprimer',
					loading: isVoucherRemovalLoading,
					onClick: handleRemove,
					startIcon: <Icon path={mdiDelete} />,
					success: !!removeVoucherData,
					variant: 'contained',
				},
			] as DialogProps['actionsDialog'],
		[handleRemove, removeVoucherData, isVoucherRemovalLoading],
	);

	// Reload the table if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && tableInstance.reload();
	}, [tableInstance, networkStatus]);

	return hasSubmittedOrValidatedVouchers ? (
		<>
			<Box flex={1}>
				<VoucherTable
					columns={tableColumns}
					dataSource={submittedOrValidatedVouchersDataSource}
					onVoucherSelection={handleVoucherSelection}
					tableInstance={tableInstance}
					title="Pièces à valider"
				/>
			</Box>

			<ConfirmationDialog
				actionsDialog={removeConfirmationDialogActions}
				content="Les informations renseignées seront perdues."
				open={!!voucherToRemoveId}
				onClose={handleRemoveConfirmationDialogClose}
				title="Êtes-vous sûr de vouloir supprimer cette pièce ?"
			/>
		</>
	) : null;
};

export default SubmittedOrValidatedVoucherTable;
