import { useMutation } from '@apollo/client';
import { Autocomplete, Dialog, DialogProps, Icon, TextField } from '@elipssolution/harfang';
import { mdiAccountPlus } from '@mdi/js';
import { Checkbox, Divider, FormControlLabel, Stack, Typography, styled } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, UseControllerProps, useForm } from 'react-hook-form';

import useCountriesDataSource from './hooks/useCountriesDataSource';
import useSupplierMasterAccountsDataSource from './hooks/useSupplierMasterAccountsDataSource';
import { useSession } from '../../../../src/components/SessionProvider';
import { DIALOG_CLOSE_DELAY } from '../../../../src/utils/dialogCloseDelay';
import { CountryType } from '../../../../utils/countries';
import { emailPattern } from '../../../../utils/emailPattern';
import { ThirdPartyTypeEnum } from '../../../accounting/types/thirdParty';
import { CREATE_THIRD_PARTY, CreateThirdPartyType } from '../../api/thirdParty';
import { AccountType } from '../../types/account';

const FormContent = styled('div')(({ theme }) => ({
	display: 'flex',
	gap: theme.spacing(2),

	'&:nth-of-type(2)': {
		marginTop: theme.spacing(2),
	},
}));

const HeadPartsTypography = styled(Typography)(({ theme }) => ({
	marginBottom: theme.spacing(1),
}));

type AddSupplierFormDialogProps = {
	isOpen: boolean;
	onClose: () => void;
};
type FormInputs = {
	code: string;
	name: string;
	isMatchable?: boolean;
	address1: string;
	address2?: string;
	zipCode: string;
	city: string;
	country: CountryType;
	siren?: string;
	email?: string;
	phone?: string;
	website?: string;
	masterAccount: Omit<AccountType, 'isMaster'>;
};
type FieldPropsType = {
	flex?: number;
	id: keyof FormInputs;
	label: string;
	required?: boolean;
	rules?: UseControllerProps['rules'];
};

const AddSupplierFormDialog = ({ isOpen, onClose }: AddSupplierFormDialogProps) => {
	const { customerFile } = useSession();
	const { id: customerFileId, connector: customerFileConnector } = customerFile || {};
	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
		setError,
		setValue,
	} = useForm<FormInputs>({
		mode: 'onChange',
	});
	const [dialogError, setDialogError] = useState<string>();
	const [isCreateThirdPartySucceeded, setIsCreateThirdPartySucceeded] = useState(false);

	const handleClose = useCallback(() => {
		reset();
		onClose?.();
		setDialogError(undefined);
	}, [onClose, reset]);

	const renderField = ({ flex, id, label, required = false, rules }: FieldPropsType) => (
		<Controller
			control={control}
			key={id}
			name={id}
			render={({ field: { value, ...field }, fieldState: { error: fieldError } }) => (
				<TextField
					{...field}
					invalid={!!fieldError}
					helperText={fieldError?.message}
					label={label || ''}
					required={required}
					value={value as string}
					size="small"
					sx={{ flex }}
				/>
			)}
			rules={{
				...rules,
				...(required && {
					required: 'Champ requis',
				}),
			}}
		/>
	);

	const {
		count: supplierMasterAccountsCount,
		dataSource: supplierMasterAccountsDataSource,
		defaultValue: defaultMasterAccount,
	} = useSupplierMasterAccountsDataSource({
		customerFileId,
	});

	useEffect(() => {
		defaultMasterAccount && setValue('masterAccount', defaultMasterAccount);
	}, [setValue, defaultMasterAccount]);

	const [createThirdParty] = useMutation<CreateThirdPartyType>(CREATE_THIRD_PARTY, {
		onCompleted: () => {
			setIsCreateThirdPartySucceeded(true);
			setTimeout(() => {
				setIsCreateThirdPartySucceeded(false);
				handleClose();
			}, DIALOG_CLOSE_DELAY);
		},
		onError: (mutationError) => {
			if ((mutationError as Error).message.includes('UQ_a2370456b386b2aa532816f2309')) {
				setError('code', { message: 'Compte déjà existant sur ce dossier.' });
			} else {
				setDialogError(`Erreur lors de l'ajout d'un fournisseur.`);
				throw mutationError;
			}
		},
	});

	const onSubmit = useCallback(
		({ masterAccount, country, ...values }: FormInputs) =>
			createThirdParty({
				variables: {
					createThirdPartyInput: {
						...values,
						type: masterAccount.name === 'clients' ? ThirdPartyTypeEnum.CUSTOMER : ThirdPartyTypeEnum.SUPPLIER,
						country: country.id,
						masterAccountId: masterAccount.id,
						state: 'submitted',
					},
				},
			}),
		[createThirdParty],
	);

	const { dataSource: countriesDataSource } = useCountriesDataSource();

	const accountCodePattern = useMemo(() => {
		if (customerFileConnector?.includes('cegid')) {
			return /^401[A-Za-z0-9 ]{1,9}$/;
		}
		if (customerFileConnector?.includes('agiris')) {
			return /^401[A-Za-z0-9]{1,7}$/;
		}

		return /^401/;
	}, [customerFileConnector]);
	const accountCodeMaxLength = useMemo(() => {
		if (customerFileConnector?.includes('cegid')) {
			return 12;
		}
		if (customerFileConnector?.includes('agiris')) {
			return 10;
		}
		return Infinity;
	}, [customerFileConnector]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: 'Annuler',
				onClick: handleClose,
			},
			{
				disabled: !isValid,
				label: isCreateThirdPartySucceeded ? 'Fournisseur ajouté' : 'Ajouter',
				success: isCreateThirdPartySucceeded,
				variant: 'contained',
				startIcon: <Icon path={mdiAccountPlus} />,
				onClick: handleSubmit(onSubmit),
			},
		],
		[handleClose, handleSubmit, isCreateThirdPartySucceeded, isValid, onSubmit],
	);

	return (
		<Dialog
			error={dialogError}
			actionsDialog={actionsDialog}
			onClose={handleClose}
			open={isOpen}
			title="Ajouter un fournisseur"
			fullWidth
		>
			<FormContent>
				<Stack gap={2} flex={3}>
					<HeadPartsTypography variant="subtitle2">Identification</HeadPartsTypography>
					{renderField({
						id: 'name',
						label: 'Nom',
						required: true,
						rules: { required: 'Champ requis' },
					})}

					<Stack flexDirection="row" gap={1} width="100%">
						{(
							[
								{
									flex: 1,
									id: 'phone',
									label: 'Téléphone',
									rules: {
										pattern: {
											value: /^(?:(?:\+|00)33|0)[1-9](?:\d{2}){4}$/,
											message: 'Numéro de téléphone invalide',
										},
									},
								},
								{
									flex: 2,
									id: 'email',
									label: 'Email',
									rules: {
										pattern: {
											value: emailPattern,
											message: 'Email invalide',
										},
									},
								},
							] as FieldPropsType[]
						).map((fieldProps) => renderField(fieldProps))}
					</Stack>

					{(
						[
							{ id: 'website', label: 'Site internet' },
							{ id: 'address1', label: 'Adresse 1', required: true },
							{ id: 'address2', label: 'Adresse 2' },
						] as FieldPropsType[]
					).map((fieldProps) => renderField(fieldProps))}

					<Stack flexDirection="row" gap={1} width="100%">
						{(
							[
								{ flex: 1, id: 'zipCode', label: 'Code postal', required: true },
								{ flex: 1, id: 'city', label: 'Ville', required: true },
							] as FieldPropsType[]
						).map((fieldProps) => renderField(fieldProps))}
						<Controller
							control={control}
							defaultValue={{ id: 'FR', label: 'France' }}
							name="country"
							rules={{ required: true }}
							render={({ field }) => {
								return (
									<Autocomplete<CountryType>
										{...field}
										dataSource={countriesDataSource}
										getOptionLabel={({ label }) => label}
										label="Pays"
										sx={{ flex: 1 }}
										disableClearable
										required
									/>
								);
							}}
						/>
					</Stack>
				</Stack>

				<Divider orientation="vertical" flexItem />

				<Stack gap={2} flex={2}>
					<HeadPartsTypography variant="subtitle2">Informations comptables</HeadPartsTypography>
					{renderField({
						id: 'siren',
						label: 'SIREN',
						rules: {
							pattern: { value: /^\d{9}$/, message: 'SIREN invalide' },
							maxLength: 9,
						},
					})}
					<Stack flexDirection="row" gap={1} width="100%">
						{renderField({
							id: 'code',
							label: 'Numéro de compte',
							required: true,
							rules: {
								pattern: { value: accountCodePattern, message: 'Numéro de compte invalide' },
								maxLength: {
									value: accountCodeMaxLength,
									message: `Numéro de compte trop long. Max. ${accountCodeMaxLength} caractères.`,
								},
							},
						})}
						<Controller
							control={control}
							name="isMatchable"
							render={({ field: { onChange, value, ...field } }) => {
								const handleChange = (_: SyntheticEvent<Element, Event>, newValue: boolean) => onChange(newValue);

								return (
									<FormControlLabel
										control={
											<Checkbox {...field} color="secondary" checked={value as boolean} onChange={handleChange} />
										}
										label="Lettrable"
										sx={{ marginLeft: 'unset', marginRight: 0 }}
									/>
								);
							}}
						/>
					</Stack>
					<Stack gap={2}>
						<Controller
							control={control}
							name="masterAccount"
							rules={{ required: true }}
							render={({ field }) => (
								<Autocomplete<Omit<AccountType, 'isMaster'>>
									{...field}
									dataSource={supplierMasterAccountsDataSource}
									disabled={!!supplierMasterAccountsCount && supplierMasterAccountsCount <= 1}
									getOptionLabel={({ name }) => name}
									label="Compte collectif associé"
									sx={{ flex: 1 }}
									disableClearable
									required
								/>
							)}
						/>
					</Stack>
				</Stack>
			</FormContent>
		</Dialog>
	);
};

export default AddSupplierFormDialog;
