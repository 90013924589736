import { ConfirmationDialog, DialogProps } from '@elipssolution/harfang';
import { useMemo } from 'react';

import { SignDocumentType } from '../../../types/document';

type DeleteDocumentConfirmationDialogProps = {
	documentToDeleteId: SignDocumentType['id'];
	onDocumentToDeleteChange: (documentId?: SignDocumentType['id']) => void;
	onDocumentDelete: (documentId: SignDocumentType['id']) => void;
};

const DeleteDocumentConfirmationDialog = ({
	documentToDeleteId,
	onDocumentToDeleteChange,
	onDocumentDelete,
}: DeleteDocumentConfirmationDialogProps) => {
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: 'Annuler',
				onClick: () => onDocumentToDeleteChange(undefined),
			},
			{
				color: 'error',
				label: 'Supprimer',

				onClick: () => {
					if (documentToDeleteId) {
						onDocumentDelete(documentToDeleteId);
						onDocumentToDeleteChange(undefined);
					}
				},
				variant: 'contained',
			},
		],
		[documentToDeleteId, onDocumentDelete, onDocumentToDeleteChange],
	);
	return (
		<ConfirmationDialog
			actionsDialog={actionsDialog}
			open={!!documentToDeleteId}
			onClose={() => onDocumentToDeleteChange(undefined)}
			title="Êtes-vous sûr de vouloir supprimer ce document ?"
		/>
	);
};
export default DeleteDocumentConfirmationDialog;
