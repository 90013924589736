import { useMutation } from '@apollo/client';
import { ConfirmationDialog, DialogProps, Icon } from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { useCallback, useMemo } from 'react';

import { DIALOG_CLOSE_DELAY } from '../../../src/utils/dialogCloseDelay';
import { REMOVE_SIGN_CONTACT, RemoveSignContactType, RemoveSignContactVariablesType } from '../api/contact';
import { SignContactType } from '../types/contact';

type DeleteContactDialogProps = {
	isOpen: boolean;
	onClose: (params?: { refreshTable?: boolean }) => void;
	contact?: SignContactType;
};

const DeleteContactDialog = ({ onClose, contact, isOpen }: DeleteContactDialogProps) => {
	const handleCloseWithRefresh = useCallback(() => onClose({ refreshTable: true }), [onClose]);

	const [removeSignContact, { loading, error, reset, data }] = useMutation<
		RemoveSignContactType,
		RemoveSignContactVariablesType
	>(REMOVE_SIGN_CONTACT, {
		onCompleted: () =>
			setTimeout(() => {
				handleCloseWithRefresh();
				reset();
			}, DIALOG_CLOSE_DELAY),
	});

	const handleDeleteContact = useMemo(() => {
		return async () => {
			if (!contact) throw new Error('A contact should already have been selected before opening the dialog.');

			await removeSignContact({
				variables: {
					id: contact.id,
				},
			});
		};
	}, [contact, removeSignContact]);

	const handleClose = useCallback(() => {
		onClose();
		reset();
	}, [onClose, reset]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: 'Annuler',
				onClick: handleClose,
				variant: 'text',
			},
			{
				color: 'error',
				label: data?.sign_removeContact.id ? 'Contact supprimé' : 'Supprimer',
				onClick: handleDeleteContact,
				startIcon: <Icon path={mdiDelete} />,
				variant: 'contained',
				loading,
				success: !!data,
				error: !!error,
				persistantErrorMessage: error?.message,
			},
		],
		[handleClose, handleDeleteContact, loading, data, error],
	);

	return (
		<ConfirmationDialog
			actionsDialog={actionsDialog}
			open={isOpen}
			maxWidth="md"
			onClose={onClose}
			title={`Êtes-vous sûr de vouloir supprimer le contact ${contact?.firstName || ''} ${contact?.lastName || ''}  ?`}
		/>
	);
};

export default DeleteContactDialog;
