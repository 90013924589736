import { Button, Icon } from '@elipssolution/harfang';
import { mdiUpload } from '@mdi/js';
import jschardet from 'jschardet';
import * as Papa from 'papaparse';
import React, { useCallback, useRef, useState } from 'react';
import * as XLSX from 'sheetjs_xlsx';

import ImportContactsDialog, { DataRow } from '../../components/contact/ImportContactsDialog';

type DataPreview = {
	columnsLength: number;
	normalizedLines: DataRow[];
};

const parseFile = (fileContent: string, encoding: string, onComplete: (parsedData: DataPreview) => void) => {
	Papa.parse<string[]>(fileContent, {
		preview: 6,
		encoding,
		skipEmptyLines: true,
		complete: (result) => {
			const lines = result.data;
			const columnsLength = lines?.[0]?.length ?? 0;

			if (columnsLength === 0 || lines.length < 1) {
				onComplete({ columnsLength: 0, normalizedLines: [] });
				return;
			}
			const normalizedLines = lines.map((line) =>
				Object.fromEntries(
					Array.from({ length: columnsLength }, (_, index) => [index.toString(), line?.[index] ?? '']),
				),
			);

			onComplete({
				columnsLength,
				normalizedLines,
			});
		},
	});
};

const ContactImport = ({ reloadContacts }: { reloadContacts: () => void }) => {
	const [selectedFields, setSelectedFields] = useState<string[]>([]);
	const [file, setFile] = useState<File>();
	const [nbColumns, setNbColumns] = useState(0);
	const [dataSample, setDataSample] = useState<DataRow[]>([]);

	const inputRef = useRef<HTMLInputElement>(null);

	const resetInputRefAndReloadContacts = useCallback(() => {
		if (inputRef?.current) {
			inputRef.current.value = '';
		}
		reloadContacts();
	}, [reloadContacts]);

	const handleFormatData = useCallback(({ columnsLength, normalizedLines }: DataPreview) => {
		setNbColumns(columnsLength);
		setDataSample(normalizedLines);
		setSelectedFields(Array(columnsLength).fill('Ne pas importer'));
	}, []);

	const handleFileChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const uploadedFile = event.target.files?.[0];

			if (uploadedFile) {
				setFile(uploadedFile);
				const fileReader = new FileReader();

				if (
					uploadedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
					uploadedFile.type === 'application/vnd.ms-excel'
				) {
					fileReader.onload = () => {
						const fileResult = fileReader.result;
						const workbook = XLSX.read(fileResult, { type: 'array', sheetRows: 6 });
						const worksheet = workbook.Sheets[workbook.SheetNames[0]];
						const csv = XLSX.utils.sheet_to_csv(worksheet);

						parseFile(csv, 'UTF-8', (dataPreview) => {
							handleFormatData(dataPreview);
						});
					};
					fileReader.readAsArrayBuffer(uploadedFile);
				} else if (uploadedFile.type === 'text/csv') {
					fileReader.onload = () => {
						const fileResult = fileReader.result as string;
						const detectedEncoding = jschardet.detect(fileResult)?.encoding || 'UTF-8';

						parseFile(fileResult, detectedEncoding, (dataPreview) => {
							handleFormatData(dataPreview);
						});
					};
					fileReader.readAsText(uploadedFile, 'UTF-8');
				}
			}
		},
		[handleFormatData],
	);

	return (
		<>
			<Button component="label" startIcon={<Icon path={mdiUpload} />}>
				Importer des contacts
				<input onChange={handleFileChange} ref={inputRef} type="file" hidden accept=".xls,.xlsx,.csv" />
			</Button>

			<ImportContactsDialog
				selectedFields={selectedFields}
				file={file}
				nbColumns={nbColumns}
				dataSample={dataSample}
				onDataSampleChange={setDataSample}
				onSelectedFieldsChange={setSelectedFields}
				onClose={resetInputRefAndReloadContacts}
			/>
		</>
	);
};

export default ContactImport;
