import { styled } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Control, Controller } from 'react-hook-form';

import PotentialSigners from './PotentialSigners';
import SelectedSigners from './SelectedSigners';
import { SignContactType } from '../../../types/contact';
import { ProcedureFormType, ProcedureType } from '../../../types/procedure';

const StyledSignersForm = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
});

type SignersFormProps = {
	control: Control<ProcedureFormType>;
	chosenContactsEmails: SignContactType['email'][];
	procedure?: ProcedureType;
	onSignerSelection: (signerEmail: SignContactType['email']) => void;
	onSignerRemoval: (signerId: SignContactType['id'], signerEmail: SignContactType['email']) => void;
};

const SignersForm = ({
	control,
	chosenContactsEmails,
	onSignerSelection,
	procedure,
	onSignerRemoval,
}: SignersFormProps) => (
	<StyledSignersForm>
		<Controller
			name="signers.signers"
			control={control}
			render={({ field: { onChange, value } }) => {
				const handleSelectSigner = (signer: SignContactType) => {
					onSignerSelection(signer.email);
					const existingSigner = procedure?.signers.find(({ email }) => email === signer.email);

					onChange([
						...(value ?? []),
						existingSigner
							? {
									id: existingSigner.id,
									firstName: existingSigner.firstName,
									lastName: existingSigner.lastName,
									email: existingSigner.email,
							  }
							: signer,
					]);
				};
				const handleUnselectSigner = (signerId: SignContactType['id'], email: SignContactType['email']) => {
					onSignerRemoval(signerId, email);
					onChange(value?.filter(({ email: contactEmail }) => contactEmail !== email));
				};
				return (
					<DndProvider backend={HTML5Backend} context={window}>
						<PotentialSigners handleSelectSigner={handleSelectSigner} chosenEmails={chosenContactsEmails} />
						<SelectedSigners
							control={control}
							handleUnselectSigner={handleUnselectSigner}
							handleOrderSigners={onChange}
							chosenContacts={value ?? []}
						/>
					</DndProvider>
				);
			}}
		/>
	</StyledSignersForm>
);
export default SignersForm;
