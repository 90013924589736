import { PlaceSignaturesDialog } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import dynamic from 'next/dynamic';
import { useCallback, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';

import DeleteDocumentConfirmationDialog from './DeleteDocumentConfirmationDialog';
import DocumentRow from './DocumentRow';
import { DocumentFieldsType, DocumentFieldTypeEnum, SignDocumentType } from '../../../types/document';
import { ProcedureType } from '../../../types/procedure';
import { SignerType } from '../../../types/signer';

const DocumentViewer = dynamic(() => import('./PdfViewer'), {
	ssr: false,
});

const StyledTableContainer = styled('div')({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
});

const StyledTableBody = styled('div')(({ theme: { typography, shape, shadows, palette } }) => ({
	...typography.body2,
	boxShadow: shadows[1],
	borderRadius: shape.borderRadius * 2,
	border: `1px solid ${palette.divider}`,
	width: '100%',
	height: 200,
	display: 'flex',
	flexDirection: 'column',
	overflowY: 'auto',
}));

const StyledTableHeader = styled('div')(({ theme: { spacing } }) => ({
	height: 24,
	display: 'flex',
	marginBottom: spacing(0.5),
}));

const StyledHeaderColumn = styled('span')({
	width: 300,
	flexGrow: 1,
	flexShrink: 1,
	textAlign: 'left',
	fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	fontWeight: '400',
	fontSize: '0.75rem',
	lineHeight: '2.66',
	letterSpacing: '0.08333em',
	textTransform: 'uppercase',
	color: 'rgba(0, 0, 0, 0.6)',
});

const StyledHeaderIconColumn = styled('div')({
	width: 40,
});

type UploadedDocumentsTableProps = {
	uploadedDocuments: SignDocumentType[];
	handleOrderDocuments: (documents: SignDocumentType[]) => void;
	onDeleteDocument: (id: SignDocumentType['id']) => void;
	signers: SignerType[];
	onSign: (index: number, fields: DocumentFieldsType) => void;
	procedureId?: ProcedureType['id'];
};

const UploadedDocumentsTable = ({
	uploadedDocuments,
	handleOrderDocuments,
	onDeleteDocument,
	signers,
	onSign,
	procedureId,
}: UploadedDocumentsTableProps) => {
	const [documentToSign, setDocumentToSign] = useState<SignDocumentType>();
	const [documentToDeleteId, setDocumentToDeleteId] = useState<SignDocumentType['id']>();
	const findDocumentIndex = useCallback(
		(id: SignDocumentType['id']) => uploadedDocuments.findIndex(({ id: docId }) => docId === id),
		[uploadedDocuments],
	);

	const handleSignatureValidation = useCallback(
		(fields: DocumentFieldsType) => {
			if (documentToSign) {
				onSign(findDocumentIndex(documentToSign.id), fields);
				setDocumentToSign(undefined);
			}
		},
		[documentToSign, findDocumentIndex, onSign],
	);

	const moveDocument = useCallback(
		(id: SignDocumentType['id'], atIndex: number) => {
			const index = findDocumentIndex(id);
			const documentsCopy = [...uploadedDocuments];
			const [documentToMve] = documentsCopy.splice(index, 1);
			documentsCopy.splice(atIndex, 0, documentToMve);
			handleOrderDocuments(documentsCopy);
		},
		[findDocumentIndex, handleOrderDocuments, uploadedDocuments],
	);

	const [, drop] = useDrop(() => ({ accept: 'DOCUMENT' }));

	const tableBody = useMemo(
		() => (
			<div ref={drop}>
				{uploadedDocuments.map((document) => (
					<DocumentRow
						key={document.id}
						document={document}
						findDocumentIndex={findDocumentIndex}
						moveDocument={moveDocument}
						onDeleteDocument={(docId: SignDocumentType['id']) => {
							setDocumentToDeleteId(docId);
						}}
						signers={signers}
						onSign={(doc: SignDocumentType) => {
							setDocumentToSign(doc);
						}}
						procedureId={procedureId}
					/>
				))}
			</div>
		),
		[drop, findDocumentIndex, moveDocument, procedureId, signers, uploadedDocuments],
	);

	const signatories = useMemo(
		() => signers.map(({ id, firstName, lastName }) => ({ id, name: `${firstName} ${lastName}`, document: [] })),
		[signers],
	);

	const documents = useMemo(
		() =>
			documentToSign && documentToSign.document
				? [
						{
							id: documentToSign.id,
							file: documentToSign.document,
							signatures: documentToSign?.fields
								?.filter(({ type, signerYousignId }) => type === DocumentFieldTypeEnum.SIGNATURE && signerYousignId)
								.map(({ x, y, signerYousignId, type, ...rest }) => ({
									x,
									y,
									signatoryId: signerYousignId as string,
									...rest,
									documentId: documentToSign.id,
								})),
							texts: documentToSign?.fields
								?.filter(({ type }) => type === DocumentFieldTypeEnum.READ_ONLY_TEXT)
								.map(({ x, y, signerYousignId, text, type, ...rest }) => ({
									x,
									y,
									content: text as string,
									...rest,
									documentId: documentToSign.id,
								})),
							mentions: documentToSign?.fields
								?.filter(({ type, signerYousignId }) => type === DocumentFieldTypeEnum.MENTION && signerYousignId)
								.map(({ x, y, signerYousignId, type, ...rest }) => ({
									x,
									y,
									signatoryId: signerYousignId as string,
									...rest,
									documentId: documentToSign.id,
								})),
						},
				  ]
				: [],
		[documentToSign],
	);
	const placeSignatureModal = useMemo(
		() =>
			documentToSign && (
				<PlaceSignaturesDialog
					open={!!documentToSign}
					connector="YouSign"
					signatories={signatories}
					documents={documents}
					onClose={() => setDocumentToSign(undefined)}
					onValidate={handleSignatureValidation}
				>
					<DocumentViewer document={documentToSign.document} />
				</PlaceSignaturesDialog>
			),
		[documentToSign, documents, handleSignatureValidation, signatories],
	);

	return (
		<>
			<StyledTableContainer>
				<StyledTableHeader>
					<StyledHeaderIconColumn />
					<StyledHeaderColumn>Nom</StyledHeaderColumn>
					<StyledHeaderColumn>Signataires</StyledHeaderColumn>
					<StyledHeaderIconColumn />
					<StyledHeaderIconColumn />
				</StyledTableHeader>

				<StyledTableBody>{tableBody}</StyledTableBody>
			</StyledTableContainer>
			{documentToDeleteId && (
				<DeleteDocumentConfirmationDialog
					documentToDeleteId={documentToDeleteId}
					onDocumentToDeleteChange={setDocumentToDeleteId}
					onDocumentDelete={onDeleteDocument}
				/>
			)}
			{placeSignatureModal}
		</>
	);
};

export default UploadedDocumentsTable;
