import { Chip } from '@elipssolution/harfang';

import { DocumentUploadStatusEnum } from '../types/documentUpload';

const StatusChip = ({ status }: { status: DocumentUploadStatusEnum }) => {
	if (status === DocumentUploadStatusEnum.WAITING) {
		return <Chip color="warning" label="En cours de dépôt" />;
	}

	return (
		<Chip
			color={status === DocumentUploadStatusEnum.PROCESSED ? 'success' : 'error'}
			label={status === DocumentUploadStatusEnum.PROCESSED ? 'Traité' : 'En attente'}
		/>
	);
};

export default StatusChip;
