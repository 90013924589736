import { useLazyQuery } from '@apollo/client';
import { Button, SettingsItemTextField } from '@elipssolution/harfang';
import { Typography, styled } from '@mui/material';
import { FocusEvent, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { Control, Controller, UseFormClearErrors, UseFormSetError } from 'react-hook-form';

import {
	CHECK_SETTING_CUSTOMER_FILE_TAG_ARCHIVED,
	CHECK_SETTING_CUSTOMER_FILE_TAG_NAME_AVAILABILITY,
	CheckSettingCustomerFileTagArchivedType,
	CheckSettingCustomerFileTagNameAvailabilityType,
} from '../../../../api/settingCustomerFileTag';
import { SettingTagByCustomerFileType } from '../../../../types/settingTag';

const ArchivedTagTooltipDiv = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: spacing(1),

	'& > div': {
		display: 'flex',
		justifyContent: 'flex-end',

		gap: spacing(1),
		marginTop: spacing(2),
	},
}));

type ArchivedTagTooltipProps = {
	archivedTagId: string;
	onTooltipContentSet: (tooltipContent: SetStateAction<ReactNode>) => void;
	onTagIdSet: (tagArchivedId: string) => void;
};

const ArchivedTagTooltip = ({ archivedTagId, onTooltipContentSet, onTagIdSet }: ArchivedTagTooltipProps) => {
	const closeTooltip = () => onTooltipContentSet(undefined);

	const applyTooltipActions = () => {
		onTagIdSet(archivedTagId);
		closeTooltip();
	};

	return (
		<ArchivedTagTooltipDiv>
			<Typography>Un tag portant le même nom a été trouvé dans les tags archivés.</Typography>
			<Typography>Voulez-vous le réactiver ?</Typography>
			<div>
				<Button onClick={closeTooltip}>Annuler</Button>
				<Button onClick={applyTooltipActions} variant="contained">
					Réactiver
				</Button>
			</div>
		</ArchivedTagTooltipDiv>
	);
};

let availabilityTimer: ReturnType<typeof setTimeout>;

type FormFieldType = Pick<SettingTagByCustomerFileType, 'color' | 'isEnabled' | 'name' | 'storageUpload'>;

type SettingCustomerFileTagNameFieldProps = {
	clearErrors: UseFormClearErrors<FormFieldType>;
	control: Control<FormFieldType>;
	customerFileId: string;
	initialValue?: string;
	onApplyArchivedTag: (tagArchivedId: string) => void;
	setError: UseFormSetError<FormFieldType>;
	tagId?: string | null;
};

const SettingCustomerFileTagNameField = ({
	clearErrors,
	control,
	customerFileId,
	initialValue,
	onApplyArchivedTag,
	setError,
	tagId,
}: SettingCustomerFileTagNameFieldProps) => {
	const [nameFieldTooltipContent, setNameFieldTooltipContent] = useState<ReactNode>();

	const [checkSettingCustomerFileTagNameAvailability] = useLazyQuery<CheckSettingCustomerFileTagNameAvailabilityType>(
		CHECK_SETTING_CUSTOMER_FILE_TAG_NAME_AVAILABILITY,
	);

	const [checkIfSettingCustomerFileTagArchived, { loading: isCheckSettingCustomerFileTagArchivedLoading }] =
		useLazyQuery<CheckSettingCustomerFileTagArchivedType>(CHECK_SETTING_CUSTOMER_FILE_TAG_ARCHIVED, {
			onCompleted: ({ document_checkSettingCustomerFileTagArchived: { tagId: archivedTagId } }) =>
				archivedTagId &&
				setNameFieldTooltipContent(
					<ArchivedTagTooltip
						archivedTagId={archivedTagId}
						onTagIdSet={onApplyArchivedTag}
						onTooltipContentSet={setNameFieldTooltipContent}
					/>,
				),
		});

	const checkCustomerFileTagNameAvailability = useCallback(
		(customerFileTagName: string) => {
			clearTimeout(availabilityTimer);

			if (initialValue === customerFileTagName) {
				clearErrors('name');
				return true;
			}

			availabilityTimer = setTimeout(() => {
				checkSettingCustomerFileTagNameAvailability({ variables: { customerFileId, name: customerFileTagName } })
					.then(({ data }) => {
						const { document_checkCustomerFileTagNameAvailability: isCustomerFileTagNameAvailable } = data || {};

						!isCustomerFileTagNameAvailable && setError('name', { message: "Nom du tag d'entreprise déjà utilisé" });

						return isCustomerFileTagNameAvailable;
					})
					.catch((e) => {
						throw e;
					});
			}, 300);

			return undefined;
		},
		[initialValue, clearErrors, checkSettingCustomerFileTagNameAvailability, customerFileId, setError],
	);

	const checkSettingCustomerFileTagArchived = useCallback(
		({ target: { value: name } }: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
			!tagId &&
				name &&
				checkIfSettingCustomerFileTagArchived({
					variables: {
						name,
					},
				}).catch((e) => {
					throw e;
				});
		},
		[checkIfSettingCustomerFileTagArchived, tagId],
	);

	return (
		<Controller
			control={control}
			name="name"
			render={({ field: { onBlur, onChange, ...field }, fieldState: { error } }) => {
				const handleChange = (value?: string) => {
					clearErrors('name');

					value && checkCustomerFileTagNameAvailability(value);

					onChange(value);
				};

				return (
					<SettingsItemTextField
						{...field}
						description="Nom du tag d'entreprise."
						disabled={isCheckSettingCustomerFileTagArchivedLoading}
						helperText={error?.message ?? ' '}
						invalid={!!error}
						label="Nom"
						onBlur={checkSettingCustomerFileTagArchived}
						onChange={handleChange}
						tooltipContent={nameFieldTooltipContent}
						required
					/>
				);
			}}
			rules={{
				required: 'Champ requis',
				validate: checkCustomerFileTagNameAvailability,
			}}
		/>
	);
};

export default SettingCustomerFileTagNameField;
