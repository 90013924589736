import { Tooltip } from '@elipssolution/harfang';
import { Avatar, AvatarGroup, Stack, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';

import { SignerType } from '../../../types/signer';

const SignerAvatar = styled(Avatar)(({ theme: { palette } }) => ({
	'&.signed': {
		backgroundColor: palette.primary.main,
		color: palette.getContrastText(palette.primary.main),
	},

	'&.rejected': {
		backgroundColor: palette.error.main,
		color: palette.getContrastText(palette.error.main),
	},
}));

const renderAdditionalAvatar = ({
	surplusSigners,
}: {
	surplusSigners: { signer: SignerType; signed?: boolean; rejected?: boolean }[];
}) => {
	const tooltipContent = (
		<Stack gap={1}>
			{surplusSigners.map(({ signer: { id, lastName, firstName } }) => (
				<Typography key={id} variant="inherit">
					{`${firstName} ${lastName}`}
				</Typography>
			))}
		</Stack>
	);

	return (
		<Tooltip content={tooltipContent}>
			<Avatar>{`+${surplusSigners.length}`}</Avatar>
		</Tooltip>
	);
};

const SignersAvatarGroup = ({
	signers,
}: {
	signers: { signer: SignerType; signed?: boolean; rejected?: boolean }[];
}) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const [maxVisible, setMaxVisible] = useState(5);

	const additionalAvatar = useMemo(
		() => ({
			additionalAvatar: {
				component: ({ children }: { children: [string, number] }) => {
					const [, restSigners] = children;
					return renderAdditionalAvatar({ surplusSigners: signers.slice(-restSigners) });
				},
			},
		}),
		[signers],
	);

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver((entries) => {
				const { width } = entries[0].contentRect;

				setMaxVisible(Math.floor(width / 44));
			}),
		[],
	);

	useEffect(() => {
		if (!containerRef.current) return undefined;

		resizeObserver.observe(containerRef.current);

		return () => resizeObserver.disconnect();
	}, [resizeObserver, containerRef]);

	return (
		<Stack width="100%" flexDirection="row" ref={containerRef}>
			<AvatarGroup total={signers.length} max={maxVisible} slotProps={additionalAvatar}>
				{signers.map(({ signer: { id, lastName, firstName }, signed, rejected }) => (
					<SignerAvatar
						className={clsx({ signed, rejected })}
						sx={{ width: 35, height: 35 }}
						key={id}
					>{`${firstName[0]}${lastName[0]}`}</SignerAvatar>
				))}
			</AvatarGroup>
		</Stack>
	);
};

export default SignersAvatarGroup;
