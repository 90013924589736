import { AutocompleteFilterDefinitionType } from '@elipssolution/harfang';
import { ListItemText } from '@mui/material';

import { SignerType } from '../types/signer';

export const renderSignerFullName = ({ firstName, lastName }: Pick<SignerType, 'firstName' | 'lastName'>) =>
	`${firstName} ${lastName}`;

export const RenderSigner = ({ firstName, lastName, email }: Pick<SignerType, 'firstName' | 'lastName' | 'email'>) => (
	<ListItemText primary={renderSignerFullName({ firstName, lastName })} secondary={email} />
);

export const getSignerFilter = (
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
	) => Promise<{
		count: number;
		items: SignerType[];
	}>,
): AutocompleteFilterDefinitionType<SignerType> => ({
	dataKey: 'email',
	label: 'Signataire',
	type: 'autocomplete',
	dataSource,
	getOptionLabel: renderSignerFullName,
	renderOptions: RenderSigner,
});
