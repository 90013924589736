import { gql } from '@apollo/client';

import { ContactFormType, SignContactType } from '../types/contact';

export const CONTACT_FIELDS = `
	id
	firstName
	lastName
	email
	isPrivate
`;

// QUERIES

export type FetchContactsType = {
	sign_contacts: {
		count: number;
		items: SignContactType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_CONTACTS = gql`
	query sign_contacts($search: String, $page: Page) {
		sign_contacts(search: $search, page: $page) {
			count
			items {
				${CONTACT_FIELDS}
				isPrivate
			}
			limit
			offset
		}
	}
`;

export type FetchSignContactsType = {
	sign_contacts: {
		count: number;
		items: SignContactType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SIGN_CONTACTS = gql`
	query sign_contacts($search: String, $filter: sign_ContactFilterInput, $page: Page, $orderBy: OrderBy) {
		sign_contacts(search: $search, filter: $filter, page: $page, orderBy: $orderBy) {
			count
			items {
				${CONTACT_FIELDS}
				email
				phoneNumber
				isPrivate
			}
			limit
			offset
		}
	}
`;

export type FetchPotentialSignersType = {
	sign_potentialSigners: {
		count: number;
		items: SignContactType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_POTENTIAL_SIGNERS = gql`
	query sign_potentialSigners($search: String, $page: Page, $excludedEmails: [String!]) {
		sign_potentialSigners(search: $search, page: $page, excludedEmails: $excludedEmails) {
			count
			items {
				${CONTACT_FIELDS}
				isPrivate
			}
			limit
			offset
		}
	}
`;

// MUTATIONS
export type CreateContactType = {
	sign_createContact: { id: SignContactType['id'] };
};

export type CreateContactMutationVariablesType = {
	createContactInput: ContactFormType;
};

export const CREATE_CONTACT = gql`
	mutation sign_createContact($createContactInput: sign_CreateContactInput!) {
		sign_createContact(createContactInput: $createContactInput) {
			id
		}
	}
`;

export type UpdateContactType = {
	sign_UpdateContact: { id: SignContactType['id'] };
};

export type UpdateContactMutationVariablesType = {
	updateContactInput: Omit<SignContactType, 'isError'>;
};

export const UPDATE_CONTACT = gql`
	mutation sign_updateContact($updateContactInput: sign_UpdateContactInput!) {
		sign_updateContact(updateContactInput: $updateContactInput) {
			${CONTACT_FIELDS}
			email
			phoneNumber
			isPrivate
		}
	}
`;

export type RemoveSignContactType = {
	sign_removeContact: {
		id: SignContactType['id'];
	};
};

export type RemoveSignContactVariablesType = {
	id: SignContactType['id'];
};

export const REMOVE_SIGN_CONTACT = gql`
	mutation sign_removeContact($id: ID!) {
		sign_removeContact(id: $id) {
			id
		}
	}
`;
