import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { Button, Icon, List, useList } from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import { useCallback, useEffect, useState } from 'react';

import ContactItem from './ContactItem';
import { FETCH_POTENTIAL_SIGNERS, FetchPotentialSignersType } from '../../../api/contact';
import { SignContactType } from '../../../types/contact';
import ContactDialog from '../../ContactDialog';

const renderContact = ({ isPrivate, firstName, lastName, email }: SignContactType) => (
	<ContactItem renderPrivacyIcon firstName={firstName} lastName={lastName} email={email} isPrivate={isPrivate} />
);

type PotentialSignersProps = {
	handleSelectSigner: (signer: SignContactType) => void;
	chosenEmails?: SignContactType['email'][];
};

const PotentialSigners = ({ handleSelectSigner, chosenEmails }: PotentialSignersProps) => {
	const listInstance = useList();

	const [currentScrollPosition, setCurrentScrollPosition] = useState<number>();
	const [itemPosition, setItemPosition] = useState<number>();
	const [isContactCreateModifyDialogOpen, setIsContactCreateModifyDialogOpen] = useState(false);

	const [fetchPotentialSigners, { networkStatus }] = useLazyQuery<FetchPotentialSignersType>(FETCH_POTENTIAL_SIGNERS, {
		notifyOnNetworkStatusChange: true,
	});

	const potentialSignersDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: SignContactType[];
		}> => {
			const { data, error } = await fetchPotentialSigners({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
					excludedEmails: chosenEmails,
				},
			});

			if (error) {
				throw error;
			}

			const {
				sign_potentialSigners: { count = 0, items = [] },
			} = data ?? {
				sign_potentialSigners: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchPotentialSigners, chosenEmails],
	);

	const handleRowClick = useCallback(
		(item: SignContactType) => {
			handleSelectSigner(item);
			setItemPosition(currentScrollPosition);
		},
		[currentScrollPosition, handleSelectSigner],
	);

	// Reload the list if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && listInstance.reload();
	}, [listInstance, networkStatus]);

	return (
		<>
			<List<SignContactType>
				style={{ width: '40%' }}
				list={listInstance}
				dataSource={potentialSignersDataSource}
				onRowClick={handleRowClick}
				isSearchEnabled
				onScrollChange={setCurrentScrollPosition}
				initialScrollOffset={itemPosition}
				searchPlaceholder="Signataires potentiels"
				rowRenderer={renderContact}
				footer={
					<Button
						variant="contained"
						startIcon={<Icon path={mdiPlus} size="small" />}
						onClick={() => setIsContactCreateModifyDialogOpen(true)}
					>
						Créer un contact
					</Button>
				}
			/>

			<ContactDialog
				isOpen={isContactCreateModifyDialogOpen}
				onClose={() => setIsContactCreateModifyDialogOpen(false)}
				listInstance={listInstance}
			/>
		</>
	);
};

export default PotentialSigners;
