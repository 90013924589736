import { useMutation, useQuery } from '@apollo/client';
import { Button, DialogProps } from '@elipssolution/harfang';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import DialogStepper from './dialogStepper/DialogStepper';
import DocumentsForm from './procedure/documentsForm/DocumentsForm';
import GlobalForm from './procedure/globalForm/GlobalForm';
import SignersForm from './procedure/signersForm/SignersForm';
import { getDocumentId, findAddedAndUpdatedDocuments } from './procedure/utils/processDocuments';
import { useSession } from '../../../src/components/SessionProvider';
import { DIALOG_CLOSE_DELAY } from '../../../src/utils/dialogCloseDelay';
import { uploadFileWithFormDataAndReturningResponse } from '../../../src/utils/file';
import { UPDATE_DOCUMENTS, UpdateDocumentsType } from '../api/document';
import {
	CREATE_PROCEDURE,
	CreateProcedureType,
	FETCH_DRAFT_PROCEDURE,
	FetchDraftProcedureType,
	START_PROCEDURE,
	StartProcedureType,
	UPDATE_PROCEDURE,
	UpdateProcedureType,
} from '../api/procedure';
import { SignContactType } from '../types/contact';
import { DocumentFieldTypeEnum, DocumentMapperType, SignDocumentType } from '../types/document';
import { ProcedureFormType, ProcedureInputType, ProcedureType } from '../types/procedure';
import { SignerType } from '../types/signer';

enum SubmitTypeEnum {
	SAVE,
	START,
}

const defaultFormValues = {
	global: {
		name: '',
		comment: '',
		isPrivate: false,
	},
	signers: {
		isSignOrdered: false,
		signers: [],
	},
};

type ProcedureCreationDialogProps = {
	isOpen: boolean;
	procedureId?: ProcedureType['id'];
	onClose: () => void;
};

const ProcedureDialog = ({ isOpen, procedureId, onClose }: ProcedureCreationDialogProps) => {
	const {
		control,
		formState: { isDirty, dirtyFields, isValid },
		handleSubmit,
		reset,
		watch,
		setValue,
	} = useForm<ProcedureFormType>();
	const { customerFile: sessionCustomerFile } = useSession();
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};

	const [chosenContactsEmails, setChosenContactsEmails] = useState<SignContactType['email'][]>([]);
	const [currentStep, setCurrentStep] = useState(0);
	const [hasSavingError, setHasSavingError] = useState(false);
	const [hasSavingSucceeded, setHasSavingSucceeded] = useState(false);
	const [hasStartingProcedureError, setHasStartingProcedureError] = useState(false);
	const [hasStartingProcedureSucceeded, setHasStartingProcedureSucceeded] = useState(false);
	const [procedure, setProcedure] = useState<ProcedureInputType>();
	const [isTriggeredByStartProcedureButton, setIsTriggeredByStartProcedureButton] = useState(false);
	const [isUploadingDocumentsLoading, setIsUploadingDocumentsLoading] = useState(false);

	const signersFormValue = watch('signers.signers');
	const documentsFormValue = watch('documents');

	const stepValidityMapper = useMemo(
		() =>
			new Map([
				[0, isValid],
				[1, signersFormValue && signersFormValue.length > 0],
				[
					2,
					signersFormValue &&
						documentsFormValue &&
						documentsFormValue.every(
							(document) =>
								!!document.fields && document.fields.some(({ type }) => type === DocumentFieldTypeEnum.SIGNATURE),
						) &&
						signersFormValue.every(({ id }) =>
							documentsFormValue.some((document) =>
								document.fields?.some(({ signerYousignId }) => signerYousignId === id),
							),
						),
				],
			]),
		[documentsFormValue, isValid, signersFormValue],
	);

	const resetStates = () => {
		setHasSavingError(false);
		setHasSavingSucceeded(false);
		setHasStartingProcedureError(false);
		setHasStartingProcedureSucceeded(false);
		setChosenContactsEmails([]);
		setProcedure(undefined);
	};

	const handleClose = useCallback(() => {
		reset(defaultFormValues);
		setCurrentStep(0);
		resetStates();
		reset();
		onClose();
	}, [onClose, reset]);

	const showSuccess = useCallback(
		(type: SubmitTypeEnum) => {
			if (type === SubmitTypeEnum.START) {
				setHasStartingProcedureSucceeded(true);
				setTimeout(() => handleClose(), DIALOG_CLOSE_DELAY);
			} else {
				setHasSavingSucceeded(true);
				setTimeout(() => setHasSavingSucceeded(false), DIALOG_CLOSE_DELAY);
			}
		},
		[handleClose],
	);

	const showError = useCallback(
		(type: SubmitTypeEnum) => {
			if (type === SubmitTypeEnum.START) {
				setHasStartingProcedureError(true);
				setTimeout(() => handleClose(), DIALOG_CLOSE_DELAY);
			} else {
				setHasSavingError(true);
				setTimeout(() => setHasSavingError(false), DIALOG_CLOSE_DELAY);
			}
		},
		[handleClose],
	);

	const uploadAllDocuments = useCallback(
		({ id, documentsToUpload }: { id: ProcedureType['id']; documentsToUpload?: SignDocumentType[] }) => {
			if (!access_token || !sessionCustomerFile?.id || !documentsToUpload || documentsToUpload.length === 0) {
				return Promise.resolve([]);
			}
			const documentsIdsMapper: DocumentMapperType[] = [];
			setIsUploadingDocumentsLoading(true);

			return documentsToUpload
				.reduce<Promise<{ previousDocumentId?: string }>>(async (previousPromise, currentDocument) => {
					const { previousDocumentId } = await previousPromise;

					const uri = new URL(`/sign/documents`, window.location.href);
					uri.searchParams.append('procedureId', id);
					if (previousDocumentId) {
						uri.searchParams.append('afterId', previousDocumentId);
					}
					if (currentDocument.fields) {
						uri.searchParams.append('fields', JSON.stringify(currentDocument.fields));
					}

					try {
						const docId = currentDocument.document
							? await uploadFileWithFormDataAndReturningResponse<string>({
									accessToken: access_token,
									file: currentDocument.document,
									uri,
									selectedCustomerFileId: sessionCustomerFile.id,
							  })
							: undefined;

						if (docId) {
							documentsIdsMapper.push({
								id: currentDocument.id,
								yousignId: docId,
							});
						}

						return { previousDocumentId: docId };
					} catch (error) {
						return Promise.reject(new Error('Unable to upload file'));
					}
				}, Promise.resolve({ previousDocumentId: undefined }))
				.then(() => {
					setIsUploadingDocumentsLoading(false);
					return documentsIdsMapper;
				});
		},
		[access_token, sessionCustomerFile?.id],
	);

	useQuery<FetchDraftProcedureType>(FETCH_DRAFT_PROCEDURE, {
		variables: { signProcedureId: procedureId },
		skip: !procedureId,
		onCompleted: ({ sign_procedure }) => {
			setChosenContactsEmails(sign_procedure?.signers.map(({ email }) => email));
			const procedureToEdit = {
				id: sign_procedure.id,
				global: {
					name: sign_procedure?.name,
					isPrivate: sign_procedure?.isPrivate,
					comment: sign_procedure?.comment,
				},
				signers: {
					isSignOrdered: sign_procedure.signers.length === 0 ? false : sign_procedure.signers?.[0]?.rank !== null,
					signers: sign_procedure?.signers.map(({ id, firstName, lastName, email }) => ({
						id,
						firstName,
						lastName,
						email,
					})),
				},

				documents: (sign_procedure?.documents ?? []).map(({ id, filename, fields }) => ({
					id,
					filename,
					fields: fields.map(({ id: fieldId, page, x, y, width, height, text, mention, type, signer }) => ({
						id: fieldId,
						page,
						x,
						y,
						mention,
						width,
						height,
						text,
						type,
						signerYousignId: signer?.id,
					})),
				})),
			};
			reset(procedureToEdit);
			setProcedure(procedureToEdit);
		},
	});

	const [createProcedureMutation, { loading: isProcedureCreationLoading }] =
		useMutation<CreateProcedureType>(CREATE_PROCEDURE);
	const [updateProcedureMutation, { loading: isProcedureUpdatingLoading }] =
		useMutation<UpdateProcedureType>(UPDATE_PROCEDURE);
	const [startProcedureMutation, { loading: isProcedureStartingLoading }] =
		useMutation<StartProcedureType>(START_PROCEDURE);

	const [updateDocumentsMutation, { loading: isUpdatingDocumentsLoading }] =
		useMutation<UpdateDocumentsType>(UPDATE_DOCUMENTS);

	const updateDocuments = useCallback(
		async (newDocuments: ProcedureFormType['documents']) => {
			if (!procedure?.id || !newDocuments) return undefined;

			const initialDocuments = procedure?.documents ?? [];

			const { documentsToCreate, documentsToUpdate } = findAddedAndUpdatedDocuments(newDocuments, initialDocuments);
			const documentsToRemove = initialDocuments
				.filter(
					({ id: initialDocumentId }) =>
						!newDocuments.some(({ id: formDocumentId }) => formDocumentId === initialDocumentId),
				)
				.map(({ id: docId }) => docId);

			const createdDocumentsIdsMapper = await uploadAllDocuments({
				id: procedure?.id,
				documentsToUpload: documentsToCreate,
			});

			if (documentsToUpdate.length > 0 || documentsToRemove.length > 0) {
				await updateDocumentsMutation({
					variables: {
						saveDocumentsInput: {
							procedureId: procedure?.id,
							updatedDocuments: documentsToUpdate.map(({ id, afterId, ...document }) => ({
								...document,
								id: getDocumentId(createdDocumentsIdsMapper, id),
								afterId: afterId ? getDocumentId(createdDocumentsIdsMapper, afterId) : undefined,
							})),
							removedDocuments: documentsToRemove,
						},
					},
				});
			}

			return newDocuments.map(({ id, ...restDocument }) => ({
				...restDocument,
				id: getDocumentId(createdDocumentsIdsMapper, id),
			}));
		},
		[procedure?.documents, procedure?.id, updateDocumentsMutation, uploadAllDocuments],
	);

	const saveProcedureCreation = useCallback(
		async ({ global, signers, documents }: ProcedureFormType): Promise<ProcedureInputType> => {
			const { data: createdProcedureData } = await createProcedureMutation({
				variables: {
					createProcedureInput: {
						global,
						signers: {
							...signers,
							signers: (signers?.signers ?? []).map(({ id }) => id),
						},
					},
				},
			});

			const { sign_createProcedure } = createdProcedureData ?? {};
			const { id: createdProcedureId, signers: yousignSigners } = sign_createProcedure ?? {};

			if (!createdProcedureId) throw new Error('An error occurred while creating the procedure.');

			const documentsToUpload = documents?.map?.(({ fields, ...restDoc }) => ({
				...restDoc,
				fields: fields?.map((field) => {
					const signerIndex = field?.signerYousignId
						? signers?.signers?.findIndex(({ id: signerId }) => signerId === field.signerYousignId)
						: undefined;
					return {
						...field,
						signerYousignId:
							signerIndex !== undefined && signerIndex !== -1 ? yousignSigners?.[signerIndex].id : undefined,
					};
				}),
			}));

			const documentsIdsMapper = await uploadAllDocuments({
				id: createdProcedureId,
				documentsToUpload,
			});

			return {
				id: createdProcedureId,
				global,
				signers: {
					isSignOrdered: yousignSigners?.length === 0 ? false : yousignSigners?.[0]?.rank !== null,
					signers: yousignSigners?.map(({ id, firstName, lastName, email }) => ({
						id,
						firstName,
						lastName,
						email,
					})),
				},
				documents: documentsToUpload?.map(({ id, ...restDoc }) => ({
					...restDoc,
					id: getDocumentId(documentsIdsMapper, id),
				})),
			};
		},
		[createProcedureMutation, uploadAllDocuments],
	);

	const saveProcedureUpdate = useCallback(
		async ({ global, signers, documents }: ProcedureFormType): Promise<ProcedureInputType> => {
			if (!procedure?.id) throw new Error('Procedure ID is missing');
			let updatedYousignSigners: SignerType[] = [];

			if (dirtyFields.global || dirtyFields.signers) {
				const updatedProcedure = await updateProcedureMutation({
					variables: {
						updateProcedureInput: {
							id: procedure?.id,
							...(dirtyFields.global && { global }),
							...(dirtyFields.signers && {
								signers: {
									...signers,
									signers: (signers?.signers ?? []).map(({ id: signerId }) => signerId),
								},
							}),
						},
					},
				});
				updatedYousignSigners = updatedProcedure.data?.sign_updateProcedure?.signers ?? [];
			}

			const newProcedure: ProcedureInputType = {
				id: procedure?.id,
				global,
				signers,
				documents,
			};
			if (dirtyFields.signers) {
				const updatedSigners = {
					isSignOrdered: updatedYousignSigners?.length === 0 ? false : updatedYousignSigners?.[0]?.rank !== null,
					signers: updatedYousignSigners?.map(({ id, firstName, lastName, email }) => ({
						id,
						firstName,
						lastName,
						email,
					})),
				};
				newProcedure.signers = updatedSigners;
				newProcedure.documents = documents?.map?.(({ fields, ...restDoc }) => ({
					...restDoc,
					fields: fields?.map((field) => {
						const signerIndex = field?.signerYousignId
							? signers?.signers?.findIndex(({ id: signerId }) => signerId === field.signerYousignId)
							: undefined;
						return {
							...field,
							signerYousignId:
								signerIndex !== undefined && signerIndex !== -1 ? updatedSigners?.signers?.[signerIndex].id : undefined,
						};
					}),
				}));
			}

			if (dirtyFields.documents) {
				const documentsWithYouSignIds = await updateDocuments(newProcedure.documents);
				newProcedure.documents = documentsWithYouSignIds;
			}

			return newProcedure;
		},
		[dirtyFields, procedure?.id, updateDocuments, updateProcedureMutation],
	);

	const saveProcedure = useCallback(
		async (submittedValues: ProcedureFormType, isTriggeredByStartProcedure = false) => {
			try {
				const updatedProcedure = await (!procedure
					? saveProcedureCreation(submittedValues)
					: saveProcedureUpdate(submittedValues));

				!isTriggeredByStartProcedure && showSuccess(SubmitTypeEnum.SAVE);
				setProcedure(updatedProcedure);
				reset(updatedProcedure);

				return { id: updatedProcedure.id };
			} catch {
				!isTriggeredByStartProcedure && showError(SubmitTypeEnum.SAVE);
				throw new Error('An error occurred while saving the procedure.');
			}
		},
		[procedure, reset, saveProcedureCreation, saveProcedureUpdate, showError, showSuccess],
	);

	const startProcedure = useCallback(
		async (submittedValues: ProcedureFormType) => {
			setIsTriggeredByStartProcedureButton(true);
			try {
				const { id: savedProcedureId } = await saveProcedure(submittedValues, true);
				await startProcedureMutation({
					variables: {
						id: savedProcedureId,
					},
				});

				showSuccess(SubmitTypeEnum.START);
			} catch {
				showError(SubmitTypeEnum.START);
			}
			setIsTriggeredByStartProcedureButton(false);
		},
		[saveProcedure, showError, showSuccess, startProcedureMutation],
	);
	const removeSignature = useCallback(
		(signerId: SignContactType['id'], signerEmail: SignContactType['email']) => {
			setChosenContactsEmails(chosenContactsEmails.filter((email) => email !== signerEmail));
			setValue(
				'documents',
				documentsFormValue?.map(({ fields, ...rest }) => ({
					fields: fields?.filter((field) => field.signerYousignId !== signerId),
					...rest,
				})),
			);
		},
		[chosenContactsEmails, documentsFormValue, setValue],
	);

	const steps = useMemo(
		() => [
			{
				number: 1,
				label: 'Informations',
				isValid: stepValidityMapper.get(0),
				content: <GlobalForm control={control} procedureId={procedure?.id} onRemoveProcedure={handleClose} />,
			},
			{
				number: 2,
				label: 'Signataires',
				isValid: stepValidityMapper.get(1),
				content: (
					<SignersForm
						control={control}
						onSignerSelection={(signerEmail) => setChosenContactsEmails([...chosenContactsEmails, signerEmail])}
						onSignerRemoval={removeSignature}
						chosenContactsEmails={chosenContactsEmails}
					/>
				),
			},
			{
				number: 3,
				label: 'Documents',
				isValid: stepValidityMapper.get(2),
				content: <DocumentsForm control={control} signers={signersFormValue ?? []} procedureId={procedure?.id} />,
			},
		],
		[chosenContactsEmails, control, handleClose, procedure?.id, removeSignature, signersFormValue, stepValidityMapper],
	);
	const handleNext = useCallback(
		() =>
			setCurrentStep((prevCurrentStep) => (prevCurrentStep < steps.length - 1 ? prevCurrentStep + 1 : prevCurrentStep)),
		[steps.length],
	);

	const handlePrevious = useCallback(
		() => setCurrentStep((prevCurrentStep) => (prevCurrentStep > 0 ? prevCurrentStep - 1 : prevCurrentStep)),
		[],
	);

	const extraInfos = useMemo(
		(): DialogProps['extraInfos'] => [
			<Button
				key="backButton"
				onClick={() => {
					if (currentStep === 0) handleClose();
					else handlePrevious();
				}}
			>
				{currentStep === 0 ? 'Annuler' : 'Précedent'}
			</Button>,
		],
		[currentStep, handlePrevious, handleClose],
	);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: !hasSavingSucceeded ? 'Sauvegarder' : 'Procédure sauvegardée',
				onClick: handleSubmit((submittedValues) => saveProcedure(submittedValues)),
				loading:
					!isTriggeredByStartProcedureButton &&
					(isProcedureCreationLoading ||
						isProcedureUpdatingLoading ||
						isUpdatingDocumentsLoading ||
						isUploadingDocumentsLoading),
				success: hasSavingSucceeded,
				error: hasSavingError,
				disabled: !(isDirty && stepValidityMapper.get(0)),
			},
			currentStep === steps.length - 1
				? {
						label: hasStartingProcedureSucceeded ? 'Procédure envoyée en signature' : 'Envoyer en signature',
						success: hasStartingProcedureSucceeded,
						error: hasStartingProcedureError,
						disabled: !(isValid && stepValidityMapper.get(2)),
						loading:
							isTriggeredByStartProcedureButton &&
							(isProcedureStartingLoading ||
								isProcedureCreationLoading ||
								isProcedureUpdatingLoading ||
								isUpdatingDocumentsLoading ||
								isUploadingDocumentsLoading),
						variant: 'contained',
						onClick: handleSubmit(startProcedure),
				  }
				: {
						label: 'Suivant',
						variant: 'contained',
						onClick: handleNext,
				  },
		],
		[
			hasSavingSucceeded,
			handleSubmit,
			isTriggeredByStartProcedureButton,
			isProcedureCreationLoading,
			isProcedureUpdatingLoading,
			isUpdatingDocumentsLoading,
			isUploadingDocumentsLoading,
			hasSavingError,
			isDirty,
			stepValidityMapper,
			currentStep,
			steps.length,
			hasStartingProcedureSucceeded,
			hasStartingProcedureError,
			isValid,
			isProcedureStartingLoading,
			startProcedure,
			handleNext,
			saveProcedure,
		],
	);

	const dialogStatus = useMemo(
		() =>
			procedure
				? {
						label: 'Brouillon',
						color: 'default',
				  }
				: undefined,
		[procedure],
	);

	return (
		<DialogStepper
			onClose={handleClose}
			dialogTitle={procedure?.global.name ?? "Création d'une procédure"}
			actionsDialog={actionsDialog}
			extraInfos={extraInfos}
			isOpen={isOpen}
			steps={steps}
			dialogStatus={dialogStatus}
			currentStep={currentStep}
			onCurrentStepChange={setCurrentStep}
		/>
	);
};

export default ProcedureDialog;
