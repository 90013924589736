import { Icon } from '@elipssolution/harfang';
import { mdiCancel, mdiCheck, mdiClose, mdiContacts, mdiFileSign, mdiProgressClock } from '@mdi/js';

import Contacts from './contacts';
import Canceled from './procedures/canceled';
import Declined from './procedures/declined';
import Finished from './procedures/finished';
import Ongoing from './procedures/ongoing';

const pages = [
	{
		icon: <Icon path={mdiFileSign} />,
		name: 'Procédures',
		route: 'procedures',
		sections: [
			{
				component: Ongoing,
				name: 'En cours',
				icon: <Icon path={mdiProgressClock} />,
				route: 'ongoing',
				id: 'procedure-ongoing',
			},
			{
				component: Canceled,
				name: 'Annulées',
				icon: <Icon path={mdiCancel} />,
				route: 'canceled',
				id: 'procedure-canceled',
			},
			{
				component: Declined,
				name: 'Rejetées',
				icon: <Icon path={mdiClose} />,
				route: 'declined',
				id: 'procedure-declined',
			},
			{
				component: Finished,
				name: 'Terminées',
				icon: <Icon path={mdiCheck} />,
				route: 'finished',
				id: 'procedure-finished',
			},
		],
	},
	{
		component: Contacts,
		icon: <Icon path={mdiContacts} />,
		name: 'Contacts',
		route: 'contacts',
	},
];

export default pages;
