import { useMemo } from 'react';

import clsx from 'clsx';

import { CircularProgress, Icon } from '@elipssolution/harfang';

import { Stack, StyledComponentProps, Typography, styled } from '@mui/material';

import { mdiAlertCircle } from '@mdi/js';

type ColorType = 'primary' | 'secondary' | 'tertiary' | 'success' | 'info' | 'error';

const KpiContainer = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	height: 75,
	minWidth: 175,

	display: 'flex',
	alignItems: 'center',
	gap: spacing(2),

	borderRadius: shape.borderRadius * 2,
	border: `1px solid ${palette.divider}`,

	padding: spacing(1),

	boxSizing: 'border-box',

	'&.error': {
		backgroundColor: `${palette.error.main}1A`,
		color: palette.error.main,
		borderColor: palette.error.main,

		'& > div:first-of-type': {
			backgroundColor: 'transparent',
			color: 'inherit',
		},
	},
}));

interface IconContainerProps extends StyledComponentProps {
	color?: ColorType;
}

const IconContainer = styled('div', {
	shouldForwardProp: (props) => props !== 'color',
})<IconContainerProps>(({ color, theme: { palette, shape } }) => {
	const backgroundColor = color ? `${palette[color].main}33` : 'transparent';

	return {
		height: '100%',
		aspectRatio: '1/1',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		borderRadius: shape.borderRadius,

		backgroundColor,
		color: color ? palette.getContrastText(backgroundColor) : 'inherit',
	};
});

type GaugeItem = {
	min?: number;
	max?: number;
	color: string;
};

type KpiCardProps = {
	color?: ColorType;
	gauge?: GaugeItem[];
	kpi: number;
	error?: boolean;
	iconPath: string;
	label: string;
	loading?: boolean;
};

const KpiCard = ({ color = 'primary', gauge, error, kpi, iconPath, label, loading }: KpiCardProps) => {
	const kpiColor = useMemo(() => {
		const { color: numberColor } = gauge?.find(({ max, min }) => (min ?? 0) <= kpi && kpi <= (max ?? Infinity)) ?? {
			color: 'default',
		};

		return numberColor;
	}, [gauge, kpi]);

	return (
		<KpiContainer className={clsx({ error })}>
			<IconContainer color={color}>
				<Icon path={error ? mdiAlertCircle : iconPath} size="large" />
			</IconContainer>
			<Stack justifyContent="center">
				<Typography fontWeight={300} variant="subtitle2">
					{label}
				</Typography>
				<Typography color={kpiColor} component="span" variant="h5">
					{error && 'Erreur'}
					{loading && <CircularProgress color="inherit" size="small" />}
					{!error && !loading && kpi}
				</Typography>
			</Stack>
		</KpiContainer>
	);
};

export default KpiCard;
