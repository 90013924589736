import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import DeclinedProceduresTable from './DeclinedProceduresTable';
import { useSession } from '../../../../../src/components/SessionProvider';
import { PermissionEnum } from '../../../../../types/permission';
import ProceduresTableHeader from '../../../components/ProceduresTableHeader';

const Declined = () => {
	const { push } = useRouter();
	const { permissions } = useSession();

	useEffect(() => {
		const hasUserPermissions = [PermissionEnum.SIGN_ACCESS].some(
			(permission) => permissions.find(({ code }) => permission === code)?.value,
		);

		if (!hasUserPermissions) {
			push('/error/403').catch((e) => {
				throw e;
			});
		}
	}, [permissions, push]);

	return (
		<Stack height="100%" gap={2}>
			<ProceduresTableHeader />

			<DeclinedProceduresTable />
		</Stack>
	);
};

export default Declined;
