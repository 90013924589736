import { Icon } from '@elipssolution/harfang';
import { mdiDragVertical } from '@mdi/js';
import { alpha, styled } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';

import ContactItem from './ContactItem';
import { SignContactType } from '../../../types/contact';
import { SignerType } from '../../../types/signer';

const StyledRow = styled('div')(({ theme: { palette } }) => ({
	borderBottom: `1px solid ${palette.divider}`,
	cursor: 'pointer',
	'&:last-of-type': {
		borderBottom: 'none',
	},
	'&:hover': {
		backgroundColor: alpha(palette.text.primary, palette.action.hoverOpacity),
		// Reset on touch devices, it doesn't add specificity
		'@media (hover: none)': {
			backgroundColor: 'transparent',
		},
	},
}));
const StyledSignerItem = styled('div')(({ theme: { spacing } }) => ({
	gap: spacing(1),
	display: 'flex',
}));

const StyledDragIcon = styled('div')(({ theme: { spacing } }) => ({
	paddingLeft: spacing(0.5),
	display: 'flex',
	alignItems: 'center',
	cursor: 'move',
}));

type SelectedSignerItemProps = {
	signer: SignerType;
	moveContact: (dragIndex: SignContactType['id'], hoverIndex: number) => void;
	isOrderingEnabled: boolean;
	findContactIndex: (id: SignContactType['id']) => number;
	onRowClick?: (contactId: SignContactType['id'], email: SignContactType['email']) => void;
};
type Item = {
	id: SignContactType['id'];
	originalIndex: number;
};
export const ItemTypes = {
	CONTACT: 'contact',
};

const SelectedSignerItem = ({
	signer: { firstName, lastName, email, id: signerId },
	moveContact,
	isOrderingEnabled,
	findContactIndex,
	onRowClick,
}: SelectedSignerItemProps) => {
	const originalIndex = findContactIndex(signerId);
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: ItemTypes.CONTACT,
			item: { id: signerId, originalIndex },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			end: (item, monitor) => {
				const { id: droppedId, originalIndex: itemIndex } = item;

				if (!monitor.didDrop()) {
					moveContact(droppedId, itemIndex);
				}
			},
		}),
		[signerId, originalIndex, moveContact],
	);

	const [, drop] = useDrop(
		() => ({
			accept: ItemTypes.CONTACT,
			hover({ id: draggedId }: Item) {
				if (draggedId !== signerId) {
					const overIndex = findContactIndex(signerId);
					moveContact(draggedId, overIndex);
				}
			},
		}),
		[findContactIndex, moveContact],
	);

	return (
		<StyledRow
			ref={isOrderingEnabled ? (node) => drag(drop(node)) : null}
			style={{ opacity: isDragging ? 0 : 1 }}
			onClick={() => onRowClick?.(signerId, email)}
		>
			<StyledSignerItem>
				{isOrderingEnabled && (
					<StyledDragIcon ref={drag}>
						<Icon path={mdiDragVertical} size="small" />
					</StyledDragIcon>
				)}
				<ContactItem firstName={firstName} lastName={lastName} email={email} />
			</StyledSignerItem>
		</StyledRow>
	);
};

export default SelectedSignerItem;
